import { VStack, HStack, CloseButton, Box } from '@chakra-ui/react';
import { TruncatableText, Button, Tabs, Tag } from 'Atoms';
import { TOP_MENU_HEIGHT } from 'containers/Navigation/pieces';
import { Typography, DesignIllustration } from 'Tokens';
import { DocumentIcon } from 'Tokens/Icons/Data';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { MdrmAnswerType, MDRM_QUESTIONS } from './MDRM.types';
import { MetricsTableData } from '../MetricAnswers.hooks';
import { AggregatedMetricsTableData } from '../AggregatedMetrics';
import { MDRMComments, MDRMOptOutMenu, MDRMOverview, MDRMSource } from './MDRMSidebarUtils';

enum MdrmSidebarTabs {
  About = 'about',
  Source = 'source',
  Comments = 'comments',
}

export const MDRMSidebar = ({
  mdrm,
  datapointId,
  setOpenMdrm,
  metric,
  openComments,
  setOpenComments,
}: {
  mdrm: MdrmAnswerType;
  datapointId: string;
  setOpenMdrm: (mdrm: MdrmAnswerType | undefined) => void;
  metric: MetricsTableData | AggregatedMetricsTableData;
  openComments: boolean;
  setOpenComments: (open: boolean) => void;
}) => {
  const mdrmQuestion = useMemo(
    () => MDRM_QUESTIONS.find((q) => q.id === mdrm.mdrmRef),
    [mdrm.mdrmRef]
  );

  const {
    companyId = '',
    esrsAssessmentId = '',
    standardRef = '',
    disclosureRequirementRef = '',
  } = useParams();
  const navigate = useNavigate();
  const openDrawer = new URLSearchParams(location.search).get('openDrawer');
  const [selectedTab, setSelectedTab] = useState<MdrmSidebarTabs | undefined>(
    MdrmSidebarTabs.Source
  );

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (openDrawer) {
      setSelectedTab(MdrmSidebarTabs.Comments);
    } else if (searchParams.get('source') === 'true') {
      setSelectedTab(MdrmSidebarTabs.Source);
    }
  }, [openDrawer, searchParams]);

  useEffect(() => {
    if (openComments) setSelectedTab(MdrmSidebarTabs.Comments);
  }, [openComments]);

  const commentsCount = useMemo(() => mdrm?.thread?.comments_aggregate.aggregate?.count, [mdrm]);

  const tabs = useMemo(() => {
    return [
      {
        title: (
          <HStack spacing="8px">
            <Typography variant="bodyStrong" color="currentColor">
              Source
            </Typography>
          </HStack>
        ),
        id: MdrmSidebarTabs.Source,
        content: <MDRMSource mdrm={mdrm} />,
      },
      {
        title: 'About',
        id: MdrmSidebarTabs.About,
        content: <MDRMOverview mdrmQuestion={mdrmQuestion} metric={metric} />,
      },
      {
        title: (
          <HStack spacing="6px">
            <Typography variant="bodyStrong" color="currentColor">
              Comments
            </Typography>
            {commentsCount && (
              <Tag variant="info" size="xs">
                {commentsCount}
              </Tag>
            )}
          </HStack>
        ),
        id: MdrmSidebarTabs.Comments,
        content: <MDRMComments mdrm={mdrm} datapointId={datapointId} />,
      },
    ];
  }, [commentsCount, mdrm, mdrmQuestion, datapointId]);

  return (
    <VStack
      minWidth="352px"
      maxWidth="352px"
      height={`calc(100vh - ${TOP_MENU_HEIGHT})`}
      pb="24px"
      mt="-16px"
      mr="unset"
      mb="unset"
      spacing="0px"
      alignItems="flex-start"
      borderLeft="1px solid"
      borderColor="border.decorative"
      overflowY="auto"
      overflowX="hidden"
      position="absolute"
      top="0px"
      right="-16px"
      bg="bg.default"
      boxSizing="border-box"
    >
      <HStack padding="8px 12px 8px 20px" justifyContent="space-between" w="100%">
        <Typography color="text.hint" variant="bodyStrong" py="8px">
          MDR-M
        </Typography>
        <CloseButton
          zIndex={2}
          onClick={() => {
            navigate(location.pathname, { replace: true });
            setOpenMdrm(undefined);
            setOpenComments(false);
          }}
        />
      </HStack>
      <Box h="100px">
        <DesignIllustration
          boxSize="244px 145px"
          w="224px"
          position="absolute"
          top={0}
          zIndex={1}
          right={0}
        />
      </Box>
      <VStack alignItems="start" width="100%" spacing="16px" p="0px 20px 12px">
        <VStack justifyContent="end" width="100%" spacing="4px" height="44px">
          <TruncatableText variant="h3" text={mdrmQuestion?.question ?? ''} />
        </VStack>
        <HStack width="100%" spacing="6px">
          <Button
            variant="secondary"
            justifyContent="space-between"
            rightIcon={<ArrowUpRightIcon color="text.action" opacity={0.4} />}
            flex={1}
            onClick={() =>
              window.open(
                `/${companyId}/esrs/${esrsAssessmentId}/report?standardRef=${standardRef}&drRef=${disclosureRequirementRef}&metricRef=${metric.metric.reference}`
              )
            }
          >
            <HStack>
              <DocumentIcon color="text.action" />
              <Typography variant="bodyStrong" color="text.action">
                Open in report
              </Typography>
            </HStack>
          </Button>
          <MDRMOptOutMenu mdrm={mdrm} />
        </HStack>
      </VStack>
      <Tabs
        currentTab={selectedTab}
        onChange={(tabId) => {
          setSelectedTab(tabId as MdrmSidebarTabs);
        }}
        padList="20px"
        items={tabs}
      />
    </VStack>
  );
};
