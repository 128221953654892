import { WidthType, TableRow, TableCell, VerticalAlign, Paragraph, Table } from 'docx';
import { QuestionType_Enum_ } from 'models';
import { AggregatedMetricsExtraData, AggregatedMetricsTableData } from '../..';
import { EsrsReportMetric, MetricInfoKey } from '../Report.types';
import { tableBorders, TextStyle } from './reportThemes';
import { TableData } from 'Molecules/NestedTable';

export const S1_6Metrics = [
  'totalEmployees',
  'totalEmployeesByContractGender',
  'totalEmployeesContractGenderAndCountry',
  'employeesContract2',
];

export const customBargainingMetrics = [
  'percentageBargainingEEA',
  'percentageBargainingNonEEA',
  'percentageRepresentativesEEA',
];

export const customGOV4NarrativeMetrics = [
  'coreElement1',
  'coreElement2',
  'coreElement3',
  'coreElement4',
  'coreElement5',
];

export const customE1_5Metrics = [
  'percentageFossilConsumption',
  'percentageRenewableConsumption',
  'percentageNuclearConsumption',
  'totalEnergyConsumption',
];

export const addEmptySpace = (spacing: number): Paragraph => {
  return new Paragraph({
    text: '',
    spacing: {
      after: spacing,
    },
  });
};

export const getRowTitle = (row: AggregatedMetricsExtraData) => {
  const isTag = !!row.tags?.length;
  const metricTitle = row.metric?.shortTitle ?? row.metric?.title;
  if (row.tagName) {
    return row.tagName;
  } else if (isTag) {
    return row.tags?.[0]?.tagValue;
  } else {
    return `${metricTitle}${
      row.tagType && row.tagType !== 'undefined' ? ` by ${row.tagType} ` : ''
    }`;
  }
};

export const getTableRowText = (row: TableData<AggregatedMetricsExtraData>) => {
  const percentage = row.percentage;
  const result = row.result?.Year ?? 0;
  const isResultNaN = isNaN(result);
  const parsedResult = isResultNaN ? 0 : parseFloat(result.toFixed(2));
  const stringifiedResult = String(parsedResult) ?? '--';

  return percentage ? stringifiedResult + ` (${percentage}%)` : stringifiedResult;
};

export const findRowByTag = (row: EsrsReportMetric['tableData'] | undefined, tagValue: string) => {
  return row?.subRows?.find((subRow) => subRow.tags?.some((tag) => tag.tagValue === tagValue));
};

const createCustomNestedDataPointsRows = (
  metricResults: AggregatedMetricsTableData,
  columns: { header: string; key: MetricInfoKey }[]
) => {
  const subRows = metricResults.subRows;

  if (!subRows?.length) return [];

  const nestedRows: TableRow[] =
    subRows?.flatMap((row) => {
      return [
        new TableRow({
          children: columns.map(
            (column) =>
              new TableCell({
                verticalAlign: VerticalAlign.TOP,
                children: [
                  column.key === 'title'
                    ? new Paragraph({
                        text: getRowTitle(row),
                        style: TextStyle.body,
                      })
                    : new Paragraph({
                        text: getTableRowText(row),
                        style: TextStyle.body,
                      }),
                ],
              })
          ),
        }),
        ...(createCustomNestedDataPointsRows(row, columns) ?? []),
      ];
    }) ?? [];

  return nestedRows.flat();
};

const createCustomDataPointRows = (
  metric: EsrsReportMetric['tableData'],
  columns: { header: string; key: MetricInfoKey }[],
  hideTotal?: boolean
) => {
  const metricInfo = metric.metric;

  const displayedRows = [
    ...createCustomNestedDataPointsRows(metric, columns),
    ...(hideTotal
      ? []
      : [
          new TableRow({
            children: columns.map(
              (column) =>
                new TableCell({
                  verticalAlign: VerticalAlign.TOP,
                  children: [
                    new Paragraph({
                      text:
                        column.key === 'title'
                          ? (metricInfo.shortTitle ?? metricInfo.title)
                          : column.key === 'result'
                            ? getTableRowText(metric)
                            : (String(metricInfo?.[column.key]) ?? ''),
                      style: TextStyle.body,
                    }),
                  ],
                })
            ),
          }),
        ]),
  ];
  return displayedRows.flat();
};

export const createCustomMetricTable = (
  metric: EsrsReportMetric['tableData'],
  columns: { header: string; key: MetricInfoKey }[],
  hideTotal?: boolean
) => {
  const quantitativeMetricTable = new Table({
    columnWidths: [5000, 5000],
    margins: {
      top: 60,
      bottom: 60,
      right: 60,
      left: 60,
    },
    width: {
      size: '100%',
      type: WidthType.PERCENTAGE,
    },
    borders: tableBorders,
    rows: [
      new TableRow({
        tableHeader: true,
        children: columns.map(
          (column) =>
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: `${100 / (columns?.length ?? 1)}%`,
                type: WidthType.PERCENTAGE,
              },
              children: [
                new Paragraph({
                  text: column.header,
                  style: TextStyle.tableTitle,
                }),
              ],
            })
        ),
      }),
      ...createCustomDataPointRows(metric, columns, hideTotal),
    ].flat(),
  });

  if (metric.metric.metricType !== QuestionType_Enum_.Decimal_) return;

  return quantitativeMetricTable;
};

export const findMetricRow = (
  metric: {
    reference: string;
    title: string;
  },
  rows: EsrsReportMetric['tableData'][]
): EsrsReportMetric['tableData'] | undefined => {
  for (const row of rows) {
    if (row.metric.reference === metric.reference) return row;
    if (row.subRows?.length) {
      const foundSubRow = findMetricRow(metric, row.subRows);
      if (foundSubRow) return foundSubRow;
    }
  }
  return undefined;
};
