import { convertInchesToTwip, Paragraph } from 'docx';
import { TextStyle } from '../reportThemes';
import { SideMenuStructure } from '../../Report.types';
import { toRomanNumeral } from '../../ReportUtils';

export const tableOfContents = (structure: SideMenuStructure) => {
  const filteredStructure = structure.filter((s) => s[0][0].reference !== 'general');
  const tableOfContentsData = filteredStructure.flatMap((category, index) => {
    const standards = category.slice(1).flatMap((standard) => {
      return standard.map((item, i) => {
        if (i === 0) return { title: item.title, level: 2 };
        return { title: item.title, level: 3 };
      });
    });
    return [
      { title: `${toRomanNumeral(index + 1)}. ${category[0][0].title}`, level: 1 },
      ...standards,
    ];
  });
  return [
    new Paragraph({
      text: 'Table of Contents',
      style: TextStyle.h1,
      spacing: { after: 300 },
    }),
    ...tableOfContentsData.map(({ title, level }) => {
      const indentation = level * 0.25;
      return new Paragraph({
        text: title,
        style: level === 1 ? TextStyle.h2 : level === 2 ? TextStyle.h3 : TextStyle.body,
        spacing: { after: 200 },
        indent: { left: convertInchesToTwip(indentation) },
      });
    }),
  ];
};
