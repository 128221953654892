import { Box, HStack, Checkbox } from '@chakra-ui/react';
import { ColumnDefResolved, Row } from '@tanstack/react-table';
import { Avatar, IconButton, TruncatableText } from 'Atoms';
import { NestedTable, TableData } from 'Molecules/NestedTable';
import { Typography } from 'Tokens';
import { ArrowNarrowRightIcon, ChevronDownIcon, ChevronRightIcon } from 'Tokens/Icons/Direction';
import { AIIcon } from 'Tokens/Icons/Function';
import { GeneratedSummaryStatus, MetricsTableData, areArraysOfObjectsEqual } from 'containers/Esrs';
import { mapUnitToCompanyCurrency } from 'containers/Esrs/utils';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { TimePeriods, TimePeriodsEnums } from '../../Requirement';
import {
  AggregatedMetricsTableData,
  AggregatedQualitativeAnswers,
  hasChildOnBULevel,
  hasChildOnSubLevel,
  hasQuarterlyFrequency,
  isFullMetricOnBULevel,
  isFullParentMetricOnSubLevel,
} from '../AggregatedMetrics/AggregatedMetrics.hooks';
import { isEqual } from 'lodash';
import { GetRequiredMaterialMetricsQuery_, QuestionType_Enum_, User } from 'models';
import { GeneratedAnswer } from '../MetricAI';
import { MetricOwnerAvatar } from '../InputTable/MetricOwnerSelect';
import { CommentsCounter } from '../AggregatedMetrics/AggregatedMetricsUtils';
import { getMetricRefNumber } from '../Metrics.hooks';
import { MetricsAttachmentsLabel } from '../InputTable/MetricAttachmentsLabel';
import { NarrativeMetricsInput } from './MetricsTableComponents/InputFields/NarrativeInputs/NarrativeInputs';
import { QuantitativeMetricsInput } from './MetricsTableComponents/InputFields/QuantitativeInputs/QuantitativeInputs';
import { LastEditedBy } from './MetricsTableComponents/LastEdited/LastEdited';
import { getLastEditedData } from './MetricsTableComponents/LastEdited/LastEdited.hooks';
import { MetricProgress } from './MetricsTableComponents/MetricProgress/MetricProgress';
import { MetricRowTitle } from './MetricsTableComponents/MetricRowTitle/MetricRowTitle';
import { MetricYear } from './MetricsTableComponents/MetricYear/MetricYear';
import { GoToSource } from './MetricsTableComponents/GoToSource/GoToSource';
import { AssessableMetrics } from '../Metrics';
import { flattenNarrativeMetrics } from '../Metrics.utils';
import { AnswerApprovalCell } from './MetricsTable.utils';
import {
  AdditionalInfoButton,
  RemoveAdditionalInfo,
} from './MetricsTableComponents/AdditionalInfoModals/AdditionalInfoModals';
import { SelectedRows } from './MetricsTableComponents/SelectedRows/SelectedRows';

export const MetricsTable = ({
  metrics,
  selectedQuarter,
  isGroup,
  currency,
  materialStandardId,
  parentStandardId,
  companyReportingUnit,
  esrsAssessmentProjectLeader,
  rowData,
  setRowData,
  pageSize = 5,
  allMetrics,
  setNumberOfRows,
  isMetricDr,
  selectedNumericMetrics,
  setSelectedNumericMetrics,
  selectedNarrativeMetrics,
  setSelectedNarrativeMetrics,
  isAI = false,
  answersData,
  setIsComments,
  generatedAnswers,
  setSelectedRow,
  onApprovalWarningModalOpen,
  isGeneratingAnswers,
  setGeneratedAnswers,
  populateQuantitativeAnswers,
  populateNarrativeAnswers,
  checkboxesDisabled = true,
  generatedSummaryStatus,
  isNarrative,
  isReadOnly,
  isBusinessUnit,
  requiredMaterialMetrics,
}: {
  metrics: AggregatedMetricsTableData[];
  selectedQuarter: TimePeriods;
  isGroup: boolean;
  currency: string;
  answersData?: AggregatedQualitativeAnswers;
  materialStandardId: string;
  parentStandardId: string | undefined;
  companyReportingUnit?: string;
  onApprovalWarningModalOpen?: () => void;
  isGeneratingAnswers?: boolean;
  esrsAssessmentProjectLeader?: Partial<User>;
  rowData?: MetricsTableData;
  setSelectedRow?: React.Dispatch<React.SetStateAction<string>>;
  setRowData: (
    param:
      | (MetricsTableData & {
          sourceData?: AggregatedQualitativeAnswers[number];
        })
      | undefined
  ) => void;
  pageSize?: number;
  allMetrics?: MetricsTableData[];
  setNumberOfRows?: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  isMetricDr: boolean;
  selectedNumericMetrics?: MetricsTableData[];
  setSelectedNumericMetrics?: Dispatch<SetStateAction<MetricsTableData[]>>;
  selectedNarrativeMetrics?: AssessableMetrics;
  setSelectedNarrativeMetrics?: Dispatch<SetStateAction<AssessableMetrics>>;
  isAI?: boolean;
  setIsComments: (val: boolean) => void;
  generatedAnswers?: GeneratedAnswer[];
  setGeneratedAnswers?: Dispatch<SetStateAction<GeneratedAnswer[]>>;
  populateQuantitativeAnswers?: (
    generatedAnswers: GeneratedAnswer[],
    numericMetrics: MetricsTableData[]
  ) => void;
  generatedSummaryStatus?: GeneratedSummaryStatus;
  populateNarrativeAnswers?: (
    generatedAnswers: GeneratedAnswer[],
    numericMetrics: AssessableMetrics
  ) => void;
  checkboxesDisabled?: boolean;
  isNarrative?: boolean;
  isReadOnly?: boolean;
  isBusinessUnit?: boolean;
  requiredMaterialMetrics: GetRequiredMaterialMetricsQuery_['esrs_MaterialMetric'];
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlDatapointId = useMemo(() => searchParams.get('datapointId'), [searchParams]);
  const isMdrmSidebarOpen = useMemo(() => !!searchParams.get('openMdrm'), [searchParams]);

  const openDrawer = new URLSearchParams(location.search).get('openDrawer');

  const isMixed = useMemo(
    () => hasChildOnBULevel(metrics[0].metric) && hasChildOnSubLevel(metrics[0].metric),
    [metrics]
  );

  const showProgress = useMemo(() => {
    if (isNarrative) return false;

    if (isBusinessUnit) return hasQuarterlyFrequency(metrics[0].metric);
    return (
      hasQuarterlyFrequency(metrics[0].metric) ||
      (hasChildOnSubLevel(metrics[0].metric) && isGroup) ||
      (hasChildOnBULevel(metrics[0].metric) && !isBusinessUnit)
    );
  }, [metrics, isNarrative, isBusinessUnit, isGroup]);

  const progressLabel = useMemo(() => {
    return hasChildOnSubLevel(metrics[0].metric) && isGroup
      ? 'Subsidiaries'
      : hasChildOnBULevel(metrics[0].metric) && !isBusinessUnit
        ? 'Business units'
        : 'Quarters';
  }, [metrics, isGroup]);

  const progressTooltip = useMemo(() => {
    if (hasChildOnBULevel(metrics[0].metric) && isGroup) {
      return 'This data point is collected from subsidiaries. The progress indicates whether all subsidiaries have added their answers.';
    } else if (hasChildOnBULevel(metrics[0].metric)) {
      return 'This data point is collected from business units. The progress indicates whether all business units have added their answers.';
    }
    return 'This data point is collected quarterly. The progress indicates whether data is collected from all quarters or only a few.';
  }, [metrics]);

  const handleRowClick = (row: MetricsTableData, isComments: boolean) => {
    if (isMdrmSidebarOpen) {
      searchParams.delete('openMdrm');
      setSearchParams(searchParams);
    }
    if (!row.referenceToSource) {
      setSelectedRow?.(row.metric.reference);
      if (openDrawer && !!urlDatapointId) navigate(pathname.split('?')[0]);
      if (generatedSummaryStatus?.isApproved === false) {
        if (onApprovalWarningModalOpen) onApprovalWarningModalOpen();
      } else {
        if (isEqual(rowData, row)) {
          setRowData(undefined);
        } else {
          setRowData({
            ...row,
            sourceData: answersData?.find((ans) => ans.metricRef === row.metric.reference),
          });
          if (isComments) {
            setIsComments(true);
          } else setIsComments(false);
        }
      }
    }
  };
  const flattenedMetrics = useMemo(
    () => flattenNarrativeMetrics(metrics.map((m) => m.metric)),
    [metrics]
  );

  const allMetricsChecked = useMemo(
    () =>
      flattenedMetrics.every((metric) =>
        selectedNarrativeMetrics?.some((row) => row.reference === metric.reference)
      ),
    [selectedNarrativeMetrics, flattenedMetrics]
  );

  const someMetricsChecked = useMemo(
    () =>
      !allMetricsChecked &&
      flattenedMetrics.some((metric) =>
        selectedNarrativeMetrics?.some((row) => row.reference === metric.reference)
      ),
    [selectedNarrativeMetrics, flattenedMetrics, allMetricsChecked]
  );

  const handleCheckAll = useCallback(() => {
    if (allMetricsChecked) {
      setSelectedNarrativeMetrics?.([]);
    } else {
      setSelectedNarrativeMetrics?.(flattenedMetrics);
    }
  }, [allMetricsChecked, flattenedMetrics, setSelectedNarrativeMetrics]);

  const isNotSpecialRow = (row: Row<TableData<MetricsTableData>>) => {
    return (
      !row.original.referenceToSource &&
      !row.original.locked &&
      !row?.original.isAdditionalInfoButton &&
      !row.original.isAdditionalInfo
    );
  };

  const showRowProgress = (row: Row<TableData<MetricsTableData>>) => {
    const metricRow = row.original;
    return (
      metricRow &&
      metricRow.metric.calculation !== 'NONE' &&
      isNotSpecialRow(row) &&
      (hasQuarterlyFrequency(metricRow.metric) ||
        (hasChildOnBULevel(metricRow.metric) && !isBusinessUnit) ||
        (hasChildOnSubLevel(metricRow.metric) && isGroup))
    );
  };

  const nestedColumns: ColumnDefResolved<TableData<AggregatedMetricsTableData>, any>[] | undefined =
    useMemo(
      () => [
        {
          header: '',
          meta: {
            width: '32px',
            padding: '16px 8px',
            verticalAlign: 'top',
            headerComponent: isNarrative && (
              <Checkbox
                isDisabled={checkboxesDisabled}
                isChecked={allMetricsChecked}
                isIndeterminate={someMetricsChecked}
                onChange={handleCheckAll}
              />
            ),
          },
          accessorKey: 'selectedRows',
          cell: ({ row }) => (
            <SelectedRows
              isNarrative={isNarrative ?? false}
              row={row.original}
              checkboxesDisabled={checkboxesDisabled}
              selectedNarrativeMetrics={selectedNarrativeMetrics}
              selectedNumericMetrics={selectedNumericMetrics}
              setSelectedNarrativeMetrics={setSelectedNarrativeMetrics}
              setSelectedNumericMetrics={setSelectedNumericMetrics}
            />
          ),
        },
        {
          header: 'Data point',
          meta: {
            width: isNarrative ? '40%' : '30%',
            verticalAlign: isNarrative ? 'top' : 'center',
            padding: isNarrative ? '0px 8px' : undefined,
          },
          accessorKey: 'metric',
          cell: ({ row }) => {
            if (row.original.isAdditionalInfoButton) {
              return (
                <Box pt="6px" pl={`${row.depth * 24}px`}>
                  <AdditionalInfoButton
                    metric={row.original.metric}
                    companyReportingUnit={companyReportingUnit ?? ''}
                  />
                </Box>
              );
            }
            return row.original.referenceToSource ? (
              <GoToSource
                row={row}
                isNarrative={isNarrative}
                filteredMetrics={allMetrics ?? []}
                setNumberOfRows={(val) => (setNumberOfRows ? setNumberOfRows(val) : {})}
              />
            ) : (
              <HStack
                spacing="8px"
                height="fit-content"
                id={
                  !row.subRows.some((subrow) => subrow.original.referenceToSource)
                    ? row.original.metric.reference
                    : undefined
                }
              >
                {row.getCanExpand() && !isNarrative && (
                  <Box onClick={(e) => e.stopPropagation()}>
                    <IconButton
                      variant={'ghost'}
                      size="md"
                      onClick={row.getToggleExpandedHandler()}
                      aria-label="expand"
                      icon={row.getIsExpanded() ? <ChevronDownIcon /> : <ChevronRightIcon />}
                    />
                  </Box>
                )}
                <MetricRowTitle
                  row={row}
                  onClick={() => handleRowClick(row.original, false)}
                  isNarrative={isNarrative}
                />
              </HStack>
            );
          },
        },
        {
          header: 'Ref.',
          meta: {
            width: '88px',
            verticalAlign: isNarrative ? 'top' : 'center',
            padding: '14px 8px',
          },
          accessorKey: 'reference',
          cell: ({ row }) =>
            row.original.isAdditionalInfo ? (
              <Typography variant="body">_</Typography>
            ) : (
              isNotSpecialRow(row) && (
                <TruncatableText text={getMetricRefNumber(row.original.metric)} />
              )
            ),
        },
        {
          header: isNarrative
            ? 'Answer'
            : selectedQuarter !== TimePeriodsEnums.year
              ? selectedQuarter
              : "Year's total",

          meta: {
            width: (() => {
              if (!showProgress && !isMetricDr) return '38%';
              if (showProgress) return '20%';
              return isNarrative ? '41%' : '30%';
            })(),
            verticalAlign: isNarrative ? 'top' : 'center',
            padding: isNarrative ? '6px 8px' : undefined,
          },
          accessorKey: 'yearly',
          cell: ({ row }) => {
            const { metric } = row.original;

            const isMetricMaterialForCompany =
              metric.materialMetrics?.[0]?.isMaterial &&
              metric.materialMetrics?.[0]?.materialStandard?.isMaterial;

            const showMetricProgress =
              hasChildOnBULevel(metric) ||
              (hasChildOnSubLevel(metric) && isGroup) ||
              hasQuarterlyFrequency(metric);

            const isCollectedOnCompanyLevel =
              !isFullMetricOnBULevel(metric) &&
              isBusinessUnit &&
              (isFullParentMetricOnSubLevel(
                metric,
                parentStandardId ?? '',
                requiredMaterialMetrics
              ) ||
                isMetricMaterialForCompany);

            const isCollectedOnGroupLevel =
              !isFullMetricOnBULevel(metric) &&
              !!parentStandardId &&
              !isGroup &&
              !isFullParentMetricOnSubLevel(metric, parentStandardId, requiredMaterialMetrics) &&
              !isMetricMaterialForCompany;

            if (row.original.isAdditionalInfoButton) return null;

            const { locked, referenceToSource, isAdditionalInfo, additionalInfoPlacement } =
              row.original;

            if (isCollectedOnCompanyLevel) {
              return (
                <Typography variant="body" w="100%" color="text.hint">
                  Collected on company level
                </Typography>
              );
            }

            if (isCollectedOnGroupLevel)
              return (
                <Typography variant="body" w="100%" color="text.hint">
                  Collected on group level
                </Typography>
              );

            if (metric.metricType !== QuestionType_Enum_.Decimal_ || isAdditionalInfo) {
              if (!locked) {
                return (
                  <NarrativeMetricsInput
                    metric={metric}
                    companyReportingUnit={companyReportingUnit ?? ''}
                    isAI={isAI}
                    isAdditionalInfo={isAdditionalInfo}
                    additionalInfoPlacement={additionalInfoPlacement}
                    isReadOnly={isReadOnly ?? false}
                    isGeneratingAnswers={isGeneratingAnswers}
                    setRowData={setRowData}
                    answersData={answersData}
                    generatedAnswers={generatedAnswers}
                  />
                );
              }
            } else {
              if (!referenceToSource && !locked) {
                return (
                  <HStack justifyContent="start" alignItems="center">
                    <Box w={isNarrative ? '50%' : '100%'}>
                      <QuantitativeMetricsInput
                        row={row}
                        metricRow={row.original}
                        metrics={metrics}
                        isNarrativeTable={isNarrative}
                        assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
                        companyReportingUnit={companyReportingUnit ?? ''}
                        isGroup={isGroup}
                        isBusinessUnit={isBusinessUnit}
                        setRowData={setRowData}
                        selectedQuarter={selectedQuarter}
                      />
                    </Box>
                    {isNarrative && (
                      <HStack alignItems="center" justifyContent="space-between" w="50%">
                        <TruncatableText
                          w="88px"
                          text={mapUnitToCompanyCurrency(
                            metric.unitOfMeasurement ?? 'NA',
                            currency
                          )}
                        />
                        {showMetricProgress && (
                          <Box minW="84px" p="6px 8px">
                            <MetricProgress
                              row={row.original}
                              answersData={answersData}
                              isGroup={isGroup}
                              companyReportingUnit={companyReportingUnit}
                            />
                          </Box>
                        )}
                      </HStack>
                    )}
                  </HStack>
                );
              }
            }
          },
        },
        {
          header: 'Unit',
          meta: {
            width: '88px',
          },
          accessorKey: 'unit',
          cell: ({ row }) => {
            const hideTotal =
              (row.original.metric.materialMetrics?.find(
                (m) => m.materialStandardId === materialStandardId
              )?.materialMetricTags.length ?? 0) > 0 &&
              !row.original.metric.showPercentageWithTag &&
              !row.original.tagName;
            return (
              !row.original.referenceToSource &&
              !row.original.locked &&
              !hideTotal && (
                <TruncatableText
                  text={
                    row.original.metric.unitOfMeasurement
                      ? mapUnitToCompanyCurrency(row.original.metric.unitOfMeasurement, currency)
                      : ''
                  }
                />
              )
            );
          },
        },
        {
          header: 'Answer',
          accessorKey: 'AIAnswer',
          meta: {
            padding: '14px 8px',
            verticalAlign: 'top',
            width: '350px',
          },
          cell: ({ row }) => {
            const generatedAnswer = generatedAnswers?.find(
              (a) =>
                a.metricRef === row.original.metric.reference &&
                areArraysOfObjectsEqual(a.tags, row.original.tags)
            );

            return (
              isNotSpecialRow(row) && (
                <MetricYear
                  row={row.original}
                  companyReportingUnit={companyReportingUnit}
                  nestedMetrics={metrics}
                  generatedAnswer={generatedAnswer}
                  isAI={isAI}
                />
              )
            );
          },
        },
        {
          header: 'Progress',
          meta: {
            width: '84px',
            subtitle: isMixed ? undefined : progressLabel,
            verticalAlign: 'center',
            tooltip: isMixed ? undefined : progressTooltip,
          },
          accessorKey: 'progress',
          cell: ({ row }) => {
            return showRowProgress(row) ? (
              <MetricProgress
                row={row.original}
                answersData={answersData}
                isGroup={isGroup}
                companyReportingUnit={companyReportingUnit}
              />
            ) : (
              <></>
            );
          },
        },
        {
          header: 'Source',
          meta: {
            width: '10%',
            padding: '14px 8px',
            verticalAlign: 'top',
          },
          accessorKey: 'source',
          cell: ({ row }) => {
            return (
              <MetricsAttachmentsLabel
                row={row.original}
                companyReportingUnitId={companyReportingUnit}
              />
            );
          },
        },
        {
          header: 'Last edited',
          accessorKey: 'lastEdited',
          meta: {
            width: '10%',
            padding: '14px 8px',
            verticalAlign: 'top',
          },
          cell: ({ row }) => {
            const generatedAnswer = generatedAnswers?.find(
              (a) =>
                a.metricRef === row.original.metric.reference &&
                areArraysOfObjectsEqual(a.tags, row.original.tags)
            );
            const isAwaitingApproval =
              generatedAnswer?.answer &&
              generatedAnswer?.status === null &&
              generatedAnswer?.answer !== '-' &&
              generatedAnswer?.answer !== 'NA';

            return (
              !row.original.referenceToSource &&
              (isAwaitingApproval ? (
                <HStack spacing="4px">
                  <Avatar name="AI" size="xs" />
                  <Typography variant="body">{getLastEditedData(new Date()).date}</Typography>
                </HStack>
              ) : (
                <LastEditedBy
                  row={row.original}
                  selectedQuarter={selectedQuarter}
                  reportingUnitId={companyReportingUnit ? companyReportingUnit : undefined}
                />
              ))
            );
          },
        },
        {
          header: 'Owner',
          meta: {
            width: '54px',
            verticalAlign: isNarrative ? 'top' : 'center',
            padding: '14px 8px',
          },
          accessorKey: 'ownerId',
          cell: ({ row }) =>
            isNotSpecialRow(row) && (
              <MetricOwnerAvatar
                row={row.original}
                selectedQuarter={selectedQuarter}
                companyReportingUnit={companyReportingUnit}
                assessmentProjectLeader={esrsAssessmentProjectLeader}
              />
            ),
        },
        {
          header: '',
          meta: {
            width: '6%',
            verticalAlign: isNarrative ? 'top' : 'center',
            padding: isNarrative ? '10px 8px' : '6px 8px',
          },
          accessorKey: 'actions',
          cell: ({ row }) => {
            return row.original.isAdditionalInfo ? (
              <HStack gap="2px" justifyContent="end">
                <RemoveAdditionalInfo
                  metric={row.original.metric}
                  companyReportingUnit={companyReportingUnit}
                  placement={row.original.additionalInfoPlacement ?? 'before'}
                />
              </HStack>
            ) : (
              row.original && isNotSpecialRow(row) && (
                <HStack gap="2px">
                  <CommentsCounter
                    row={row.original}
                    selectedQuarter={selectedQuarter}
                    companyReportingUnit={companyReportingUnit}
                    onClick={() => handleRowClick(row.original, true)}
                  />
                  <IconButton
                    className="metricArrow"
                    aria-label="side-bar"
                    size="sm"
                    variant="ghost"
                    icon={<ArrowNarrowRightIcon />}
                    onClick={() => handleRowClick(row.original, false)}
                  />
                </HStack>
              )
            );
          },
        },
        {
          // @ts-ignore
          header: () => {
            return (
              <HStack spacing="8px">
                <AIIcon />
                <Typography variant="bodyStrong">Approve</Typography>
              </HStack>
            );
          },
          meta: {
            padding: '10px 8px',
            verticalAlign: 'top',
            headerComponent: (
              <HStack spacing="8px">
                <AIIcon />
                <Typography variant="detailStrong">Approve</Typography>
              </HStack>
            ),
          },
          accessorKey: 'ai-approval',
          cell: ({ row }) => {
            const generatedAnswer = generatedAnswers?.find(
              (a) =>
                a.metricRef === row.original.metric.reference &&
                areArraysOfObjectsEqual(a.tags, row.original.tags)
            );

            const isGeneratedAnswerValid =
              generatedAnswer?.answer &&
              generatedAnswer.answer !== 'NA' &&
              generatedAnswer.answer !== '-';

            return isNarrative
              ? generatedAnswer && generatedAnswer.answer !== '-' && (
                  <AnswerApprovalCell
                    generatedAnswers={generatedAnswers ?? []}
                    setGeneratedAnswers={setGeneratedAnswers}
                    row={{
                      metric: row.original.metric as MetricsTableData['metric'],
                      tags: [],
                    }}
                    assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
                    companyReportingUnitId={companyReportingUnit}
                    populateNarrativeAnswers={populateNarrativeAnswers}
                  />
                )
              : !row.original.referenceToSource && isGeneratedAnswerValid && (
                  <AnswerApprovalCell
                    row={row.original}
                    populateQuantitativeAnswers={populateQuantitativeAnswers}
                    generatedAnswers={generatedAnswers ?? []}
                    setGeneratedAnswers={setGeneratedAnswers}
                    companyReportingUnitId={companyReportingUnit}
                    assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
                  />
                );
          },
        },
      ],
      [
        selectedQuarter,
        materialStandardId,
        checkboxesDisabled,
        selectedNarrativeMetrics,
        selectedNumericMetrics,
        generatedAnswers,
        isMdrmSidebarOpen,
        searchParams,
      ]
    );

  const filteredColumns = useMemo(() => {
    if (!nestedColumns) return [];

    return nestedColumns.filter((col) => {
      const { accessorKey = '' } = col;

      const conditions = [
        !showProgress && accessorKey === 'progress',
        isNarrative && ['unit', 'progress', 'mdr-m'].includes(accessorKey),
        !(isAI && selectedNumericMetrics && setSelectedNumericMetrics) &&
          accessorKey === 'selectedRows',
        isAI && ['unit', 'progress', 'yearly', 'mdr-m'].includes(accessorKey),
        !isAI && ['AIAnswer', 'source', 'lastEdited'].includes(accessorKey),
        !(isAI && !!generatedAnswers?.length) && accessorKey === 'ai-approval',
        !isMetricDr && accessorKey === 'mdr-m',
      ];

      return !conditions.some(Boolean);
    });
  }, [
    nestedColumns,
    selectedQuarter,
    showProgress,
    isNarrative,
    isAI,
    selectedNumericMetrics,
    setSelectedNumericMetrics,
    generatedAnswers,
    isMetricDr,
  ]);

  return (
    <NestedTable<AggregatedMetricsTableData>
      columns={filteredColumns}
      data={metrics}
      expanded={true}
      pageSize={isNarrative ? undefined : pageSize}
      headerVariant="detailStrong"
      withInnerBorder={!isNarrative}
      cellProps={(row) => {
        if (isNarrative) {
          return {
            borderTop:
              !row?.isAdditionalInfoButton &&
              !row?.isAdditionalInfo &&
              (!row?.metric.parentMetrics.length ||
                row?.metric.metricType === QuestionType_Enum_.Decimal_)
                ? '1px solid'
                : 'none',
            borderBottom:
              row?.metric.metricType === QuestionType_Enum_.Decimal_ ? '1px solid' : 'none',
            borderColor: 'border.decorative',
          };
        }
        return {};
      }}
      rowProps={(row) => {
        const generatedAnswer = generatedAnswers?.find(
          (a) => a.metricRef === row?.metric.reference && areArraysOfObjectsEqual(a.tags, row?.tags)
        )?.answer;

        const isRowSelected = isEqual(
          {
            metric: row?.metric,
            sourceData: answersData?.find((ans) => ans.metricRef === row?.metric.reference),
          },
          rowData
        );

        const getBackgroundColor = () => {
          if (row?.locked) return 'bg.disabled';

          if (isNarrative) {
            if (generatedAnswer === '-') return 'bg.warning';
            if (generatedAnswer) return 'bg.selected';
            if (isRowSelected) return 'bg.hover';
            return '';
          }

          if (isRowSelected) return 'bg.hover';
          if (generatedAnswer === 'NA') return 'bg.warning';
          if (generatedAnswer) return 'bg.selected';

          return '';
        };

        return {
          bg: getBackgroundColor(),
          transition: '0.1s',
          sx: {
            '.metricTitle': isRowSelected
              ? {
                  textDecoration: 'underline',
                  textDecorationColor: 'text.hint',
                  textUnderlineOffset: '2px',
                }
              : {},
          },
          _hover:
            !row?.isAdditionalInfoButton && !row?.locked && !row?.isAdditionalInfo
              ? {
                  transition: '0.15s ease-in-out',
                  bg: 'bg.hover',
                  '.metricTitle': {
                    textDecoration: 'underline',
                    textDecorationColor: 'text.hint',
                    textUnderlineOffset: '2px',
                  },
                  '&:hover .metricArrow': {
                    bg: 'bg.hover',
                  },
                }
              : {},
        };
      }}
    />
  );
};
