import {
  CompanyAssessmentDocument_,
  DocumentationFile,
  GroupAssessmentDocument_,
  useAddFileMutation,
  useCompanyAssessmentQuery,
  useGroupAssessmentQuery,
  useUpdateGroupAssessmentResultsMutation,
} from 'models';
import { nhost } from 'utils/nhost';
import { useCurrentCompany, useToast } from 'utils/hooks';
import { useTranslation } from 'utils/translation';
import saveAs from 'file-saver';
import { useCallback, useMemo } from 'react';
import { useFileUpload, useUserData } from '@nhost/react';
import { useParams } from 'react-router-dom';
import { parseConsolidatedResultsToCached } from './ParseConsolidatedCSV';
import { useAllActivities } from 'Features/Screening/Screening.hooks';
import Papa from 'papaparse';
import { groupOwnerContext } from 'containers/GroupAssessment/GroupAssessment.hooks';

export const useUploadConsolidatedCsv = () => {
  const { upload } = useFileUpload();
  const [updateGroupAssessmentResults] = useUpdateGroupAssessmentResultsMutation();
  const [upsertDocumentationFile] = useAddFileMutation();
  const { gAssessmentId } = useParams();
  const { company } = useCurrentCompany();
  const activities = useAllActivities();
  const user = useUserData();
  const toast = useToast();
  const { t } = useTranslation('group');

  const { data: gAssessmentData } = useGroupAssessmentQuery({
    variables: {
      assessmentId: gAssessmentId,
    },
    context: groupOwnerContext,
  });
  const consolidatedCompanyAssessmentId = useMemo(
    () => gAssessmentData?.assessment?.consolidatedCompanyAssessmentId,
    [gAssessmentData]
  );
  const aggregateId = useMemo(
    () => gAssessmentData?.assessment?.consolidatedCompanyAssessment?.aggregateId,
    [gAssessmentData]
  );

  const { data: consolidatedCompanyAssessmentData } = useCompanyAssessmentQuery({
    variables: {
      cAssessmentId: consolidatedCompanyAssessmentId ?? '',
    },
  });

  const consolidatedCompanyAssessment = useMemo(
    () => consolidatedCompanyAssessmentData?.companyAssessment,
    [consolidatedCompanyAssessmentData]
  );
  return useCallback(
    (file: File) => {
      upload({
        file,
      }).then(async (uploadedFile) => {
        if (uploadedFile.isUploaded) {
          Papa.parse<any[]>(file, {
            skipEmptyLines: 'greedy',
            header: false,
            dynamicTyping: true,
            complete: async (results) => {
              try {
                const consolidateCompanyAssessmentObjects = await parseConsolidatedResultsToCached({
                  results,
                  activities,
                  groupCompany: company,
                  user: user ?? undefined,
                  gAssessmentId,
                  consolidatedCompanyAssessment,
                });
                await upsertDocumentationFile({
                  variables: {
                    input: {
                      title: file.name,
                      description: 'Consolidated Group financials',
                      companyId: company?.id,
                      uploadedById: user?.id,
                      source: '',
                      storageFileId: uploadedFile.id,
                    },
                  },
                  onCompleted: async (docFileData) => {
                    await updateGroupAssessmentResults({
                      variables: {
                        groupAssessmentId: gAssessmentId,
                        uploadedAt: new Date(),
                        deletedAt: new Date(),
                        consolidatedCsvId: docFileData.file?.id ?? '',
                        consolidatedCompanyAssessmentId: consolidatedCompanyAssessmentId ?? '',
                        bAssessments: consolidateCompanyAssessmentObjects.bAssessments,
                        aggregateId: aggregateId ?? '',
                        companyId: company?.id ?? '',
                        cachedResult: consolidateCompanyAssessmentObjects.cachedResult,
                      },
                      refetchQueries: [GroupAssessmentDocument_, CompanyAssessmentDocument_],
                      onCompleted: () => {
                        toast({
                          text: t('toast.uploadSuccess'),
                        });
                      },
                      onError: () => {
                        toast({
                          text: t('toast.uploadFail', t('error.consolidatedAssessment')),
                          variant: 'danger',
                        });
                      },
                    });
                  },
                  onError: () => {
                    toast({
                      text: t('toast.uploadFail', t('error.documentationFile')),
                      variant: 'danger',
                    });
                  },
                });
              } catch (e: any) {
                const error: string | undefined = e?.message ?? '';

                toast({
                  text: t('toast.parsingFail', { error }),
                  variant: 'danger',
                });
              }
            },
          });
        } else if (uploadedFile.isError) {
          toast({
            text: t('toast.uploadFail'),
            variant: 'danger',
          });
        }
      });
      return file;
    },
    [
      upload,
      consolidatedCompanyAssessmentId,
      consolidatedCompanyAssessment,
      gAssessmentId,
      company,
      user,
      activities,
      toast,
      t,
      aggregateId,
      upsertDocumentationFile,
      updateGroupAssessmentResults,
    ]
  );
};

export const useDownloadAttachedFile = () => {
  const toast = useToast();
  const { t } = useTranslation('group');

  return useCallback(
    (file: DocumentationFile | undefined | null) => {
      if (!file) {
        toast({
          text: t('toast.downloadFail'),
          variant: 'danger',
        });
        return;
      }
      return nhost.storage
        .getPresignedUrl({ fileId: file?.storageFile?.id })
        .then(({ presignedUrl }) => {
          if (presignedUrl?.url) saveAs(presignedUrl.url, file.title);
        })
        .catch(() => {
          toast({
            text: t('toast.downloadFail'),
            variant: 'danger',
          });
        });
    },
    [toast, t]
  );
};
