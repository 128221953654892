import { Thead, Table as ChakraTable } from '@chakra-ui/react';
import { AttachmentDrawer } from 'Features/Screening/AttachmentsDrawer.tsx';
import { GetFinancialsDocumentationByIdDocument_ } from 'models/index.ts';
import { StateStatus } from 'Molecules/CardStatus/CardStatus.tsx';
import { InputCard } from 'Molecules/InputCard/InputCard.tsx';
import { useTranslation } from 'react-i18next';
import { allFinancialSections } from 'utils/financials.ts';
import { FinancialTableColumnHeader } from './TableComponents/FinancialColumnHeader.tsx';
import { FinancialTableFooter } from './TableComponents/FinancialTableFooter.tsx';
import { useGetFinancialsSummaryData } from './FinancialTables.hooks.ts';

export const FinancialSummary = () => {
  const { t } = useTranslation('common');

  const {
    notEligibleFinancials,
    eligibleFinancials,
    totalFinancials,
    documentationData,
    currency,
    isMarkedAsDone,
  } = useGetFinancialsSummaryData();

  return (
    <AttachmentDrawer
      isOpen={documentationData.isAttachmentDrawerOpen}
      refetch={[GetFinancialsDocumentationByIdDocument_]}
      onClose={documentationData.onAttachmentDrawerClose}
      attachmentBox={documentationData.attachmentBox}
    >
      <InputCard
        documentation={documentationData.documentation}
        header={{
          title: t('common:financials.summaryHeader'),
          status: isMarkedAsDone ? StateStatus.done : StateStatus.todo,
        }}
        {...{ minWidth: '800px', width: '100%' }}
      >
        <ChakraTable>
          <Thead>
            <FinancialTableColumnHeader title="" />
            {allFinancialSections.map((section) => (
              <FinancialTableColumnHeader
                title={t(
                  `common:financials.${section}${
                    section.includes('adapt') ? t('common:words.short') : ''
                  }`
                )}
                helpLabel={t(`common:financials.${section}Tooltip`)}
                caption={currency}
              />
            ))}
          </Thead>
          <FinancialTableFooter
            isTotal
            title={t('bUnits:financials.total')}
            financials={totalFinancials}
            helpLabel={t('common:financials.totalTooltip')}
          />
          <FinancialTableFooter
            title={t('bUnits:financials.notEligible')}
            financials={notEligibleFinancials}
            helpLabel={t('common:financials.notEligibleTooltip')}
          />
          <FinancialTableFooter
            title={t('bUnits:financials.eligible')}
            financials={eligibleFinancials}
            helpLabel={t('common:financials.eligibleTooltip')}
          />
        </ChakraTable>
      </InputCard>
    </AttachmentDrawer>
  );
};
