import { captureException } from '@sentry/browser';
import { addYears } from 'date-fns';
import {
  Answer_Arr_Rel_Insert_Input_,
  Thread_Constraint_,
  Thread_Update_Column_,
  NoteHistory_Constraint_,
  NoteHistory_Update_Column_,
  Attachment_Constraint_,
  Attachment_Update_Column_,
  AttachmentBox_Constraint_,
  AttachmentBox_Update_Column_,
  InsertBusinessUnitAssessmentMutation_,
  ReportingGroup_Insert_Input_,
  BusinessUnitAssessment_Constraint_,
  BusinessUnitAssessment_Update_Column_,
  useUpsertCompanyAssessmentMutation,
  useInsertBusinessUnitAssessmentMutation,
  useUpsertReportingGroupsMutation,
  BusinessUnitAssessment_Arr_Rel_Insert_Input_,
  Financials_Constraint_,
  Financials_Update_Column_,
  Answer_Constraint_,
  Answer_Update_Column_,
  ActivityReport_Constraint_,
  ActivityReport_Update_Column_,
  BusinessUnitAssessment_Insert_Input_,
  CompanyAssessmentsDocument_,
  DuplicationCompanyAssessmentQuery_,
  DuplicationCompanyAssessmentQueryVariables_,
  DuplicationCompanyAssessmentDocument_,
  DuplicationBusinessUnitAssessmentQuery_,
  DuplicationBusinessUnitAssessmentQueryVariables_,
  DuplicationBusinessUnitAssessmentDocument_,
} from 'models';
import { useCallback } from 'react';
import { dateToYYYYMMDD } from 'utils/date';
import { createFinancials } from 'utils/financials';
import { useToast } from 'utils/hooks';
import { nhost } from 'utils/nhost';
import { useTranslation } from 'utils/translation';

export type DuplicateAssessmentOptions = {
  withStructure: boolean;
  withActivities: boolean;
  withAnswers: boolean;
  withDocuments: boolean;
  withNotes: boolean;
  withComments: boolean;
  withFinancials: boolean;
};

export const defaultDuplicationOptions: DuplicateAssessmentOptions = {
  withStructure: true,
  withActivities: true,
  withAnswers: true,
  withDocuments: true,
  withNotes: true,
  withComments: true,
  withFinancials: true,
};

const duplicateAnswer = (
  answer: NonNullable<
    DuplicationBusinessUnitAssessmentQuery_['assessment']
  >['activityReports'][number]['answers'][number],
  companyId: string,
  options: DuplicateAssessmentOptions
): Answer_Arr_Rel_Insert_Input_['data'][number] => {
  const result: Answer_Arr_Rel_Insert_Input_['data'][number] = {
    questionId: answer.questionId,
    answeredById: answer.answeredById,
    data: options?.withAnswers ? answer.data : undefined,
    thread: options?.withComments
      ? {
          data: {
            companyId: companyId,
            comments: {
              data: (answer.thread?.comments ?? [])?.map(
                (c: { data: any; authorId: any; createdAt: any; updatedAt: any; id: any }) => ({
                  data: c.data,
                  authorId: c.authorId,
                  createdAt: c.createdAt,
                  updatedAt: c.updatedAt,
                  duplicatedFrom: c.id,
                })
              ),
            },
          },
          on_conflict: {
            constraint: Thread_Constraint_.ThreadAnswerIdKey_,
            update_columns: [Thread_Update_Column_.AnswerId_],
          },
        }
      : undefined,
    noteHistory: {
      data: {
        notes: {
          data: options?.withNotes
            ? (answer.noteHistory?.notes?.map(
                (note: { body?: any; authorId: any; updatedAt: any }) => ({
                  body: note.body,
                  authorId: note.authorId,
                  updatedAt: note.updatedAt,
                })
              ) ?? [])
            : [],
        },
      },
      on_conflict: {
        constraint: NoteHistory_Constraint_.NoteHistoryAnswerIdKey_,
        update_columns: [NoteHistory_Update_Column_.AnswerId_],
      },
    },
    attachmentBox: {
      data: {
        attachments: {
          data: options?.withDocuments
            ? (answer.attachmentBox?.attachments ?? [])?.map((at: { fileId: any }) => ({
                fileId: at.fileId,
              }))
            : [],
          on_conflict: {
            constraint: Attachment_Constraint_.AttachmentAttachmentBoxIdFileIdKey_,
            update_columns: [Attachment_Update_Column_.AttachmentBoxId_],
          },
        },
      },
      on_conflict: {
        constraint: AttachmentBox_Constraint_.AttachmentBoxAnswerIdKey_,
        update_columns: [AttachmentBox_Update_Column_.AnswerId_],
      },
    },
  };
  return result;
};

const processGroup = async (
  reportingGroups: DuplicationCompanyAssessmentQuery_['reportingGroups'],
  group: DuplicationCompanyAssessmentQuery_['reportingGroups'][number],
  assessment: DuplicationCompanyAssessmentQuery_['assessment'],
  uploadedBusinessUnits: InsertBusinessUnitAssessmentMutation_['bAssessment'][]
): Promise<ReportingGroup_Insert_Input_> => {
  const uploadedBusinessUnitsData = uploadedBusinessUnits.map((bu) => ({
    name: bu?.businessUnit?.name,
    businessUnitId: bu?.businessUnit?.id,
    cAssessmentId: bu?.cAssessmentId,
  }));

  const businessUnitsInPreviousGroup = assessment?.businessUnits
    .filter((bu) => bu.reportingGroupId === group.id)
    .map((bu) => ({
      name: bu.businessUnit?.name,
      businessUnitId: bu.businessUnit?.id,
      cAssessmentId: bu.cAssessmentId,
    }));

  const businessUnitsData = businessUnitsInPreviousGroup?.map((bu) => {
    const uploadedBusinessUnit = uploadedBusinessUnitsData.find((ubu) => ubu.name === bu.name);
    return {
      businessUnitId: uploadedBusinessUnit?.businessUnitId,
      cAssessmentId: uploadedBusinessUnit?.cAssessmentId,
    };
  });

  if (!businessUnitsData) {
    throw new Error('Business units data is missing');
  }

  const subGroups = reportingGroups.filter((subGroup) => subGroup.parentId === group.id);

  const processedSubGroups = await Promise.all(
    subGroups.map(async (subGroup) =>
      processGroup(reportingGroups, subGroup, assessment, uploadedBusinessUnits)
    )
  );

  return {
    companyAssessmentId: uploadedBusinessUnits[0]?.cAssessmentId,
    ownerId: group.ownerId,
    name: group.name,
    orderIndex: group.orderIndex,
    businessUnits: {
      data: businessUnitsData,
      on_conflict: {
        constraint:
          BusinessUnitAssessment_Constraint_.BusinessUnitAssessmentCAssessmentIdBusinessUnitIdN_8db61b88U_,
        update_columns: [BusinessUnitAssessment_Update_Column_.ReportingGroupId_],
      },
    },
    reportingSubGroups: {
      data: processedSubGroups,
    },
  };
};

const processReportingGroups = async (
  reportingGroups: DuplicationCompanyAssessmentQuery_['reportingGroups'],
  assessment: DuplicationCompanyAssessmentQuery_['assessment'],
  uploadedBusinessUnits: InsertBusinessUnitAssessmentMutation_['bAssessment'][]
) => {
  if (!reportingGroups) return [];

  const processedGroups = [];

  const parentGroups = reportingGroups.filter((group) => !group.parentId);
  for (const parentGroup of parentGroups) {
    const processedGroup = await processGroup(
      reportingGroups,
      parentGroup,
      assessment,
      uploadedBusinessUnits
    );
    processedGroups.push(processedGroup);
  }

  return processedGroups;
};

export const useDuplicateAssessment = () => {
  const toast = useToast();
  const [saveCompanyAssessment] = useUpsertCompanyAssessmentMutation();
  const [saveBusinessUnitAssessment] = useInsertBusinessUnitAssessmentMutation();
  const [saveReportingGroups] = useUpsertReportingGroupsMutation();
  const { t } = useTranslation(['assessment']);

  return useCallback(
    async (
      cAssessmentId: string,
      newStartDate: Date,
      options: DuplicateAssessmentOptions = defaultDuplicationOptions
    ) => {
      const newEndDate = addYears(newStartDate, 1);

      try {
        // Step 1: Save the company assessment
        const { data: companyData } = await nhost.graphql.request<
          DuplicationCompanyAssessmentQuery_,
          DuplicationCompanyAssessmentQueryVariables_
        >(DuplicationCompanyAssessmentDocument_, {
          id: cAssessmentId,
        });

        const assessment = companyData?.assessment;
        const reportingGroups = companyData?.reportingGroups ?? [];

        if (assessment) {
          const assessmentGeneralBA = assessment?.generalAssessment?.[0];

          const generalAssessment: BusinessUnitAssessment_Arr_Rel_Insert_Input_ = {
            data: [
              {
                hasGeneralAssessment: assessmentGeneralBA.hasGeneralAssessment,
                businessUnitId: null,
                activityReports: {
                  data: assessmentGeneralBA?.activityReports?.map((ar) => ({
                    activityRef: ar.activityRef,
                    activityVersionNumber: ar.activityVersionNumber,
                    financials: {
                      data: options?.withFinancials
                        ? {
                            revenue: ar.financials?.revenue ?? 0,
                            capex: ar.financials?.capex ?? 0,
                            opex: ar.financials?.opex ?? 0,
                            adaptationCapex: ar.financials?.adaptationCapex ?? 0,
                            adaptationOpex: ar.financials?.adaptationOpex ?? 0,
                            isEstimate: ar.financials?.isEstimate ?? true,
                            noteHistory: {
                              data: {
                                notes: {
                                  data: options?.withNotes
                                    ? (ar.financials?.noteHistory?.notes?.map((note) => ({
                                        body: note.body,
                                        authorId: note.authorId,
                                        updatedAt: note.updatedAt,
                                      })) ?? [])
                                    : [],
                                },
                              },
                              on_conflict: {
                                constraint: NoteHistory_Constraint_.NoteHistoryFinancialsIdKey_,
                                update_columns: [NoteHistory_Update_Column_.FinancialsId_],
                              },
                            },
                            attachmentBox: {
                              data: {
                                attachments: {
                                  data: options?.withDocuments
                                    ? (ar.financials?.attachmentBox?.attachments ?? [])?.map(
                                        (at) => ({
                                          fileId: at.fileId,
                                        })
                                      )
                                    : [],
                                  on_conflict: {
                                    constraint:
                                      Attachment_Constraint_.AttachmentAttachmentBoxIdFileIdKey_,
                                    update_columns: [Attachment_Update_Column_.AttachmentBoxId_],
                                  },
                                },
                              },
                              on_conflict: {
                                constraint: AttachmentBox_Constraint_.AttachmentBoxFinancialsIdKey_,
                                update_columns: [AttachmentBox_Update_Column_.FinancialsId_],
                              },
                            },
                          }
                        : createFinancials(0, 0, 0, 0),
                      on_conflict: {
                        constraint: Financials_Constraint_.FinancialsReportId_801651ecUniq_,
                        update_columns: [
                          Financials_Update_Column_.Revenue_,
                          Financials_Update_Column_.Capex_,
                          Financials_Update_Column_.Opex_,
                          Financials_Update_Column_.AdaptationCapex_,
                          Financials_Update_Column_.AdaptationOpex_,
                          Financials_Update_Column_.IsEstimate_,
                        ],
                      },
                    },
                    answers: {
                      data:
                        ar.answers?.map((a) => duplicateAnswer(a, assessment.companyId, options)) ??
                        [],
                      on_conflict: {
                        constraint: Answer_Constraint_.AnswerQuestionIdReportId_22331f7fUniq_,
                        update_columns: [Answer_Update_Column_.Data_],
                      },
                    },
                  })),
                  on_conflict: {
                    constraint:
                      ActivityReport_Constraint_.ActivityReportBAssessmentIdActivityRef_614b7372Uniq_,
                    update_columns: [ActivityReport_Update_Column_.CachedResultId_],
                  },
                },
              },
            ],
            on_conflict: {
              constraint:
                BusinessUnitAssessment_Constraint_.BusinessUnitAssessmentCAssessmentIdBusinessUnitIdN_8db61b88U_,
              update_columns: [BusinessUnitAssessment_Update_Column_.CAssessmentId_],
            },
          };

          const comparisonFinancials = options?.withFinancials
            ? {
                revenue: companyData.assessment?.comparisonTotalFinancials?.revenue ?? 0,
                capex: companyData.assessment?.comparisonTotalFinancials?.capex ?? 0,
                opex: companyData.assessment?.comparisonTotalFinancials?.opex ?? 0,
                adaptationCapex: 0,
                adaptationOpex: 0,
                isEstimate: companyData.assessment?.comparisonTotalFinancials?.isEstimate ?? true,
              }
            : createFinancials();

          const res = await saveCompanyAssessment({
            variables: {
              cAssessment: {
                endDate: dateToYYYYMMDD(newEndDate),
                startDate: dateToYYYYMMDD(newStartDate),
                companyId: assessment?.companyId,
                duplicatedFrom: assessment?.id,
                aggregate: {
                  data: {
                    title: `[COPY] ${assessment?.aggregate?.title}`,
                    interval: assessment?.aggregate?.interval,
                    contactPersonId: assessment?.aggregate?.contactPerson?.id,
                    startDate: dateToYYYYMMDD(newStartDate),
                    companyId: assessment?.companyId,
                  },
                },
                bAssesssments: generalAssessment,
                totalFinancials: {
                  data: {
                    ...comparisonFinancials,
                  },
                  on_conflict: {
                    constraint: Financials_Constraint_.FinancialsCAssessmentIdKey_,
                    update_columns: [Financials_Update_Column_.UpdatedAt_],
                  },
                },
              },
            },
          });

          const uploadedBusinessUnits: InsertBusinessUnitAssessmentMutation_['bAssessment'][] = [];

          if (res.data?.cAssessment?.id && options.withStructure) {
            // Step 2: Query and save each business unit assessment
            const businessUnits = assessment.businessUnits ?? [];
            const savePromises = businessUnits.map(async (bu) => {
              const { data: buData } = await nhost.graphql.request<
                DuplicationBusinessUnitAssessmentQuery_,
                DuplicationBusinessUnitAssessmentQueryVariables_
              >(DuplicationBusinessUnitAssessmentDocument_, {
                id: bu.id,
              });

              const bAssessment = buData?.assessment;

              if (bAssessment) {
                const bAssessmentInsert: BusinessUnitAssessment_Insert_Input_ = {
                  duplicatedFrom: bAssessment.id,
                  businessUnit: {
                    data: {
                      name: bAssessment.businessUnit?.name,
                      companyId: bAssessment.businessUnit?.companyId,
                      contactPersonId: bAssessment.businessUnit?.contactPersonId,
                      description: bAssessment.businessUnit?.description,
                      labels: bAssessment.businessUnit?.labels ?? [],
                      duplicatedFromId: bAssessment.businessUnit?.id,
                    },
                  },
                  hasGeneralAssessment: bAssessment.hasGeneralAssessment,
                  orderIndex: bAssessment?.orderIndex,
                  activityReports: {
                    data: options.withActivities
                      ? bAssessment.activityReports.map((act) => ({
                          activityRef: act.activityRef,
                          activityVersionNumber: act.activityVersionNumber,
                          financials: {
                            data: options?.withFinancials
                              ? {
                                  revenue: act.financials?.revenue ?? 0,
                                  capex: act.financials?.capex ?? 0,
                                  opex: act.financials?.opex ?? 0,
                                  adaptationCapex: act.financials?.adaptationCapex ?? 0,
                                  adaptationOpex: act.financials?.adaptationOpex ?? 0,
                                  isEstimate: act.financials?.isEstimate ?? true,
                                  noteHistory: {
                                    data: {
                                      notes: {
                                        data: options?.withNotes
                                          ? (act.financials?.noteHistory?.notes?.map((note) => ({
                                              body: note.body,
                                              authorId: note.authorId,
                                              updatedAt: note.updatedAt,
                                            })) ?? [])
                                          : [],
                                      },
                                    },
                                    on_conflict: {
                                      constraint:
                                        NoteHistory_Constraint_.NoteHistoryFinancialsIdKey_,
                                      update_columns: [NoteHistory_Update_Column_.FinancialsId_],
                                    },
                                  },
                                  attachmentBox: {
                                    data: {
                                      attachments: {
                                        data: options?.withDocuments
                                          ? (act.financials?.attachmentBox?.attachments ?? [])?.map(
                                              (at) => ({
                                                fileId: at.fileId,
                                              })
                                            )
                                          : [],
                                        on_conflict: {
                                          constraint:
                                            Attachment_Constraint_.AttachmentAttachmentBoxIdFileIdKey_,
                                          update_columns: [
                                            Attachment_Update_Column_.AttachmentBoxId_,
                                          ],
                                        },
                                      },
                                    },
                                    on_conflict: {
                                      constraint:
                                        AttachmentBox_Constraint_.AttachmentBoxFinancialsIdKey_,
                                      update_columns: [AttachmentBox_Update_Column_.FinancialsId_],
                                    },
                                  },
                                }
                              : createFinancials(0, 0, 0, 0),
                            on_conflict: {
                              constraint: Financials_Constraint_.FinancialsReportId_801651ecUniq_,
                              update_columns: [
                                Financials_Update_Column_.Revenue_,
                                Financials_Update_Column_.Capex_,
                                Financials_Update_Column_.Opex_,
                                Financials_Update_Column_.AdaptationCapex_,
                                Financials_Update_Column_.AdaptationOpex_,
                                Financials_Update_Column_.IsEstimate_,
                              ],
                            },
                          },
                          answers: {
                            data:
                              act.answers?.map((a) =>
                                duplicateAnswer(a, assessment.companyId, options)
                              ) ?? [],
                            on_conflict: {
                              constraint: Answer_Constraint_.AnswerQuestionIdReportId_22331f7fUniq_,
                              update_columns: [Answer_Update_Column_.Data_],
                            },
                          },
                          attachmentBox: {
                            data: {
                              attachments: {
                                data: options?.withDocuments
                                  ? (act.attachmentBox?.attachments ?? [])?.map((at) => ({
                                      fileId: at.fileId,
                                    }))
                                  : [],
                                on_conflict: {
                                  constraint:
                                    Attachment_Constraint_.AttachmentAttachmentBoxIdFileIdKey_,
                                  update_columns: [Attachment_Update_Column_.AttachmentBoxId_],
                                },
                              },
                            },
                            on_conflict: {
                              constraint:
                                AttachmentBox_Constraint_.AttachmentBoxActivityReportIdKey_,
                              update_columns: [AttachmentBox_Update_Column_.ActivityReportId_],
                            },
                          },
                          noteHistory: {
                            data: {
                              notes: {
                                data: options?.withNotes
                                  ? (act.noteHistory?.notes?.map((note) => ({
                                      body: note.body,
                                      authorId: note.authorId,
                                      updatedAt: note.updatedAt,
                                    })) ?? [])
                                  : [],
                              },
                            },
                            on_conflict: {
                              constraint: NoteHistory_Constraint_.NoteHistoryActivityReportIdKey_,
                              update_columns: [NoteHistory_Update_Column_.ActivityReportId_],
                            },
                          },
                        }))
                      : [],
                    on_conflict: {
                      constraint:
                        ActivityReport_Constraint_.ActivityReportBAssessmentIdActivityRef_614b7372Uniq_,
                      update_columns: [ActivityReport_Update_Column_.CachedResultId_],
                    },
                  },
                };

                const bAssessmentRes = await saveBusinessUnitAssessment({
                  variables: {
                    bAssessment: {
                      ...bAssessmentInsert,
                      cAssessmentId: res.data?.cAssessment?.id,
                    },
                  },
                });

                const bAssessmentData = bAssessmentRes.data?.bAssessment;
                if (bAssessmentData !== null && bAssessmentData !== undefined) {
                  uploadedBusinessUnits.push(bAssessmentData);
                }
              }
            });

            await Promise.all(savePromises);
          }

          // Step 3: Process and save reporting groups if needed
          if (options.withStructure && reportingGroups?.length) {
            const processedReportingGroups = await processReportingGroups(
              reportingGroups,
              assessment,
              uploadedBusinessUnits
            );

            await saveReportingGroups({
              variables: {
                input: processedReportingGroups,
              },
              refetchQueries: [CompanyAssessmentsDocument_],
            });
          }

          toast({
            text: t('assessment:hook.toast.duplicated.successful'),
          });
          return res;
        }
      } catch (e) {
        captureException(e);
        toast({
          text: t('assessment:hook.toast.duplicated.failed'),
          variant: 'danger',
        });
      }
    },
    [saveCompanyAssessment, saveBusinessUnitAssessment, saveReportingGroups, t, toast]
  );
};
