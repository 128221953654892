import { Paragraph, Table, TableCell, TableRow, VerticalAlign, WidthType } from 'docx';
import { EsrsReportMetric } from '../../Report.types';
import { tableBorders, TextStyle } from '../reportThemes';
import { uniq } from 'lodash';
import { findRowByTag } from '../report-helper-functions';

type SubRow = NonNullable<EsrsReportMetric['tableData']['subRows']>[number];
type AnswersData = {
  title: string;
  answers: Record<string, number | string>;
}[];

const commonMetricTags = {
  totalEmployeesBy: [
    { tagValue: 'Permanent employees', title: 'Number of permanent employees (head count / FTE)' },
    { tagValue: 'Temporary employees', title: 'Number of temporary employees (head count / FTE)' },
    {
      tagValue: 'Non-guaranteed hours employees',
      title: 'Number of non-guaranteed (head count / FTE)',
    },
  ],
  employeesContract2: [
    { tagValue: 'Full-time employees', title: 'Number of full-time employees (head count / FTE)' },
    { tagValue: 'Part-time employees', title: 'Number of part-time employees (head count / FTE)' },
  ],
};
const filterRowsMatchingTags = (
  subRows: EsrsReportMetric['tableData']['subRows'],
  tagValue1: string,
  tagValue2: string
) => {
  return subRows?.filter(
    (subRow) =>
      subRow.tags?.some((tag) => tag.tagValue === tagValue1) &&
      subRow.tags?.some((tag) => tag.tagValue === tagValue2)
  );
};

const getDoubleLevelTagTotal = (
  row: EsrsReportMetric['tableData'],
  tagValue1: string,
  tagValue2: string
) => {
  const nestedRows = row.subRows
    ?.flatMap((subRow) => subRow?.subRows)
    .filter((s): s is SubRow => !!s);

  const tagValueRows = filterRowsMatchingTags(nestedRows, tagValue1, tagValue2);

  const tagValuesResults =
    tagValueRows?.map((r) => r?.result?.Year).filter((res): res is number => res !== undefined) ??
    [];

  if (!tagValuesResults.length) {
    return '';
  }

  return tagValuesResults.reduce((a, b) => a + b, 0);
};

const getTotal = (array: (string | number)[]) => {
  return array.reduce((a, b) => {
    const v1 = typeof a === 'string' ? 0 : a;
    const v2 = typeof b === 'string' ? 0 : b;
    return v1 + v2;
  }, 0);
};

const processGenderMetricsData = (
  metrics: EsrsReportMetric['tableData'][],
  genderMetricRefs: string[]
) => {
  const sortedMetrics = metrics
    ?.filter((m) => genderMetricRefs.includes(m.metric.reference))
    ?.sort((metric1, metric2) => {
      const indexA = genderMetricRefs.indexOf(metric1?.metric?.reference);
      const indexB = genderMetricRefs.indexOf(metric2?.metric?.reference);

      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      return indexA - indexB;
    });

  return sortedMetrics.flatMap((metric) => {
    const metricInfo = metric?.metric;

    if (metricInfo.reference === 'employeesGender') {
      const title = metricInfo.shortTitle ?? metricInfo.title;
      const answers = {
        female: findRowByTag(metric, 'Female')?.result?.Year ?? '',
        male: findRowByTag(metric, 'Male')?.result?.Year ?? '',
        other: findRowByTag(metric, 'Other gender')?.result?.Year ?? '',
        notReported: findRowByTag(metric, 'Not reported')?.result?.Year ?? '',
        total: metric?.result?.Year ?? '',
      };
      return { title, answers };
    } else if (metricInfo.reference === genderMetricRefs[1]) {
      const rows = commonMetricTags.totalEmployeesBy.map(({ title, tagValue }) => {
        const female = getDoubleLevelTagTotal(metric, 'Female', tagValue);
        const male = getDoubleLevelTagTotal(metric, 'Male', tagValue);
        const other = getDoubleLevelTagTotal(metric, 'Other gender', tagValue);
        const notReported = getDoubleLevelTagTotal(metric, 'Not reported', tagValue);
        const answers = {
          female,
          male,
          other,
          notReported,
          total: getTotal([female, male, other, notReported]),
        };
        return { title, answers };
      });
      return rows;
    } else {
      const rows = commonMetricTags.employeesContract2.map(({ title, tagValue }) => {
        const female = getDoubleLevelTagTotal(metric, 'Female', tagValue);
        const male = getDoubleLevelTagTotal(metric, 'Male', tagValue);
        const other = getDoubleLevelTagTotal(metric, 'Other gender', tagValue);
        const notReported = getDoubleLevelTagTotal(metric, 'Not reported', tagValue);
        const answers = {
          female,
          male,
          other,
          notReported,
          total: getTotal([female, male, other, notReported]),
        };
        return { title, answers };
      });
      return rows;
    }
  });
};

const createGenderTableContentRows = (dataRows: AnswersData) => {
  return dataRows.map((rowData) => {
    const { title, answers } = rowData;
    return new TableRow({
      children: [
        new TableCell({
          verticalAlign: VerticalAlign.TOP,
          children: [new Paragraph({ text: title, style: TextStyle.body })],
        }),
        ...['female', 'male', 'other', 'notReported', 'total'].map(
          (key) =>
            new TableCell({
              verticalAlign: VerticalAlign.TOP,
              children: [
                new Paragraph({
                  text: String(answers[key as keyof typeof answers]),
                  style: TextStyle.body,
                }),
              ],
            })
        ),
      ],
    });
  });
};

export const createS16GenderTable = (
  metrics: EsrsReportMetric['tableData'][],
  genderMetricRefs: string[]
) => {
  const dataRows = processGenderMetricsData(metrics, genderMetricRefs);
  const tableContentRows = createGenderTableContentRows(dataRows);

  const headerRow = new TableRow({
    tableHeader: true,
    children: [
      new TableCell({
        verticalAlign: VerticalAlign.CENTER,
        width: { size: '16%', type: WidthType.PERCENTAGE },
        children: [new Paragraph({ text: '', style: TextStyle.tableTitle })],
      }),
      ...['FEMALE', 'MALE', 'OTHER (*)', 'NOT DISCLOSED', 'TOTAL'].map(
        (header) =>
          new TableCell({
            verticalAlign: VerticalAlign.CENTER,
            width: { size: '16%', type: WidthType.PERCENTAGE },
            children: [new Paragraph({ text: header, style: TextStyle.tableTitle })],
          })
      ),
    ],
  });

  return new Table({
    columnWidths: [1666, 1666, 1666, 1666, 1666, 1666],
    margins: { top: 60, bottom: 60, right: 60, left: 60 },
    width: { size: '100%', type: WidthType.PERCENTAGE },
    borders: tableBorders,
    rows: [headerRow, ...tableContentRows],
  });
};

const processCountryMetricsData = (
  metrics: EsrsReportMetric['tableData'][],
  countries: string[],
  countryMetricRefs: string[]
) => {
  const sortedMetrics = metrics
    ?.filter((m) => countryMetricRefs.includes(m.metric.reference))
    ?.sort((metric1, metric2) => {
      const indexA = countryMetricRefs.indexOf(metric1?.metric?.reference);
      const indexB = countryMetricRefs.indexOf(metric2?.metric?.reference);

      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      return indexA - indexB;
    });

  return sortedMetrics.flatMap((metric) => {
    const metricInfo = metric.metric;

    if (metricInfo.reference === 'employeesCountry') {
      const title = metricInfo.shortTitle ?? metricInfo.title;

      const answers = countries.reduce(
        (totals, country) => {
          const countryRow = findRowByTag(metric, country);
          const total = countryRow?.result?.Year;
          totals[country] = total ?? '';
          return totals;
        },
        {} as Record<string, number | string>
      );
      const total = getTotal(Object.values(answers));
      return { title, answers: { ...answers, total } };
    } else if (metricInfo.reference === 'totalEmployeesContractGenderAndCountry') {
      const rows = commonMetricTags.totalEmployeesBy.map(({ title, tagValue }) => {
        const answers = countries.reduce(
          (totals, country) => {
            const total = getDoubleLevelTagTotal(metric, country, tagValue);
            totals[country] = total;
            return totals;
          },
          {} as Record<string, number | string>
        );
        const total = getTotal(Object.values(answers));
        return { title, answers: { ...answers, total } };
      });
      return rows;
    } else {
      const rows = commonMetricTags.employeesContract2.map(({ title, tagValue }) => {
        const answers = countries.reduce(
          (totals, country) => {
            const total = getDoubleLevelTagTotal(metric, country, tagValue);
            totals[country] = total;
            return totals;
          },
          {} as Record<string, number | string>
        );
        const total = getTotal(Object.values(answers));
        return { title, answers: { ...answers, total } };
      });
      return rows;
    }
  });
};

const createCountryTableContentRows = (dataRows: AnswersData, countries: string[]) => {
  return dataRows.map((rowData) => {
    const { title, answers } = rowData;
    return new TableRow({
      children: [
        new TableCell({
          verticalAlign: VerticalAlign.TOP,
          children: [new Paragraph({ text: title, style: TextStyle.body })],
        }),
        ...[...countries, 'total'].map(
          (key) =>
            new TableCell({
              verticalAlign: VerticalAlign.TOP,
              children: [
                new Paragraph({
                  text: String(answers[key as keyof typeof answers]),
                  style: TextStyle.body,
                }),
              ],
            })
        ),
      ],
    });
  });
};

export const createS16CountryTable = (
  metrics: EsrsReportMetric['tableData'][],
  countryMetricRefs: string[]
) => {
  const countries = uniq(
    metrics
      .flatMap((metric) =>
        metric.metric.materialMetrics?.[0]?.materialMetricTags
          .find((tag) => tag.tagType === 'Country')
          ?.materialTagValues.flatMap((value) => value.tagValue)
      )
      .filter((t): t is string => !!t)
  );
  const columnsNb = (countries?.length + 2) / 100;
  const dataRows = processCountryMetricsData(metrics, countries, countryMetricRefs);
  const tableContentRows = createCountryTableContentRows(dataRows, countries);

  const headerRow = new TableRow({
    tableHeader: true,
    children: [
      new TableCell({
        verticalAlign: VerticalAlign.CENTER,
        width: { size: `${columnsNb}%`, type: WidthType.PERCENTAGE },
        children: [new Paragraph({ text: '', style: TextStyle.tableTitle })],
      }),
      ...countries.map(
        (header) =>
          new TableCell({
            verticalAlign: VerticalAlign.CENTER,
            width: { size: `${columnsNb}%`, type: WidthType.PERCENTAGE },
            children: [new Paragraph({ text: header, style: TextStyle.tableTitle })],
          })
      ),
      new TableCell({
        verticalAlign: VerticalAlign.CENTER,
        width: { size: `${columnsNb}%`, type: WidthType.PERCENTAGE },
        children: [new Paragraph({ text: 'Total', style: TextStyle.tableTitle })],
      }),
    ],
  });

  return new Table({
    columnWidths: Array(countries?.length + 2).fill(Math.floor(10000 / (countries?.length + 2))),
    margins: { top: 60, bottom: 60, right: 60, left: 60 },
    width: { size: '100%', type: WidthType.PERCENTAGE },
    borders: tableBorders,
    rows: [headerRow, ...tableContentRows],
  });
};
