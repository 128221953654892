import React, { useMemo } from 'react';
import { DRQAnswersType, DRQuestionsType } from '../..';
import { QuestionType_Enum_ } from 'models';
import { VStack } from '@chakra-ui/react';
import { AutoResizeTextarea } from 'Atoms';
import { MenuSelector } from 'Molecules/Menu/MenuSelector';
import { ESRSInputArticle } from 'containers/Esrs/EsrsUtilComponents';

export const SelectInputAnswer = ({
  answers,
  setAnswers,
  question,
}: {
  answers: DRQAnswersType;
  setAnswers: (params: DRQAnswersType) => void;
  question: DRQuestionsType[number];
}) => {
  const options = useMemo(
    () =>
      question?.choiceSet?.choices?.map((choice) => ({
        label: choice.title,
        value: choice.reference,
      })),
    [question]
  );

  const choices = useMemo(
    () => answers?.[question.reference]?.choices as string[] | undefined,
    [answers]
  );

  const text = useMemo(() => answers?.[question.reference]?.value as string | undefined, [answers]);

  const handleChangeAnswer = (selectedChoices: string[] | undefined, val?: string) => {
    setAnswers({
      ...answers,
      [question.reference]: {
        value: val ?? null,
        choices: selectedChoices,
        factValue: undefined,
      },
    });
  };

  return (
    <VStack alignItems="start" width="100%" spacing="2px">
      <MenuSelector
        isSingleSelect={question.questionType === QuestionType_Enum_.SingleChoice_}
        options={options ?? []}
        handleChange={handleChangeAnswer}
        data={choices}
        extra={<ESRSInputArticle type="choices" />}
      />
      <AutoResizeTextarea
        variant="ghost"
        borderRadius="6px"
        lineHeight="20px"
        size="md"
        mb="4px"
        placeholder="Write your answer"
        value={text ?? ''}
        onChange={({ target }) => {
          handleChangeAnswer(choices, target.value);
        }}
        onClick={(e) => e.stopPropagation()}
      />
    </VStack>
  );
};
