import {
  BareFinancials,
  Financials,
  Financials_Set_Input_,
  ObjectiveKeyEnum,
  useGetActivityReportObjectivesStateFromFinancialsQuery,
  useGetFinancialsByIdQuery,
  useUpdateFinancialsByIdMutation,
} from 'models/index.ts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetCurrentCAssessment } from 'utils/hooks/General.hooks.ts';
import { MAX_ALLOWED_NUMBER } from '../FinancialTables.hooks.ts';

const validateFinancials = (
  newValue: number,
  section: string,
  financials: Financials
): {
  validationErrorMessage: string | undefined;
  validationValue: number;
} => {
  if (newValue < 0) {
    return { validationErrorMessage: "Value can't be less than 0", validationValue: 0 };
  }
  if (section === 'capex' && newValue < financials?.adaptationCapex) {
    return {
      validationErrorMessage: `Value can't be less than adaptation capex - this value has been set to ${financials?.adaptationCapex}`,
      validationValue: financials?.adaptationCapex ?? 0,
    };
  }
  if (section === 'opex' && newValue < financials?.adaptationOpex) {
    return {
      validationErrorMessage: `Value can't be less than adaptation opex - this value has been set to ${financials?.adaptationOpex}`,
      validationValue: financials?.adaptationOpex ?? 0,
    };
  }
  if (section === 'adaptationCapex' && newValue > financials?.capex) {
    return {
      validationErrorMessage: `Value can't be greater than capex - this value has been set to ${financials?.capex}`,
      validationValue: financials?.capex ?? 0,
    };
  }
  if (section === 'adaptationOpex' && newValue > financials?.opex) {
    return {
      validationErrorMessage: `Value can't be greater than opex - this value has been set to ${financials?.opex}`,
      validationValue: financials?.opex ?? 0,
    };
  }
  if (newValue > MAX_ALLOWED_NUMBER) {
    return {
      validationErrorMessage: `Value can't be greater than ${MAX_ALLOWED_NUMBER}`,
      validationValue: MAX_ALLOWED_NUMBER,
    };
  }

  return { validationErrorMessage: undefined, validationValue: newValue };
};

function revalidateFinancials(
  section: string,
  financials: Financials,
  setErrorMessage: (errorMessage: string | undefined) => void
) {
  const { validationErrorMessage } = validateFinancials(
    financials?.[section as keyof BareFinancials],
    section,
    financials
  );
  setErrorMessage(validationErrorMessage);
}

const useActivityAdaptationFinancialsLock = ({
  section,
  financialsId,
}: {
  section: string;
  financialsId: string;
}) => {
  const isAdaptationSection = section.includes(ObjectiveKeyEnum.adaptation);
  const { data, loading } = useGetActivityReportObjectivesStateFromFinancialsQuery({
    variables: {
      financialsId,
    },
    skip: !isAdaptationSection,
  });

  if (isAdaptationSection) {
    const objectivesState = data?.ActivityReport[0].cachedResult?.objectivesState;
    if (
      !objectivesState?.significantHarmObjectives?.includes(ObjectiveKeyEnum.adaptation) &&
      objectivesState?.substantialContributionObjectives?.includes(ObjectiveKeyEnum.adaptation)
    ) {
      return { adaptationLock: false, loading };
    } else {
      return { adaptationLock: true, loading };
    }
  } else {
    return { adaptationLock: false, loading: false };
  }
};

export const useGetFinancialInputData = ({
  section,
  financialsId,
}: {
  section: string;
  financialsId: string;
}) => {
  const { isLocked } = useGetCurrentCAssessment();
  const [updateFinancials] = useUpdateFinancialsByIdMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const { data: financialsData } = useGetFinancialsByIdQuery({
    variables: {
      id: financialsId,
    },
  });

  const { adaptationLock, loading: adaptationLockLoading } = useActivityAdaptationFinancialsLock({
    section,
    financialsId,
  });

  const financials: Financials | undefined = useMemo(
    () => financialsData?.Financials_by_pk ?? undefined,
    [financialsData]
  );
  useEffect(() => {
    if (errorMessage !== undefined && !!financials) {
      revalidateFinancials(section, financials, setErrorMessage);
    }
  }, [financials]);

  const updateFinancialsById = useCallback(
    async (newValue: number) => {
      const isValueChanged = newValue !== financials?.[section as keyof BareFinancials];
      if (!!financials && isValueChanged) {
        const { validationErrorMessage, validationValue } = validateFinancials(
          newValue,
          section,
          financials
        );
        const set: Financials_Set_Input_ = { [section]: validationValue };

        setLoading(true);
        await updateFinancials({
          onError: () => {
            setLoading(false);
            setErrorMessage('Failed to update financials');
          },
          onCompleted: () => {
            setLoading(false);
            if (validationErrorMessage) {
              setErrorMessage(validationErrorMessage);
            } else {
              setErrorMessage(undefined);
            }
          },
          variables: {
            id: financialsId,
            _set: set,
          },
        });
      }
    },
    [updateFinancials, section, financials]
  );

  const value: number = useMemo(() => {
    if (financials) {
      return financials?.[section as keyof BareFinancials] ?? '';
    }
  }, [financials, section]);

  const isDisabled = useMemo(() => !financials?.isEstimate, [financials]);

  const resetErrorMessage = () => {
    setErrorMessage(undefined);
  };

  return {
    value,
    isLocked,
    isAdaptationLocked: adaptationLock,
    isDisabled,
    errorMessage,
    onChange: updateFinancialsById,
    loading: loading || adaptationLockLoading,
    resetErrorMessage,
  };
};
