import { Box, ListItem, UnorderedList, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens/Typography/Typography.tsx';
import { useTranslation } from 'utils/translation/translation.ts';

export const ConfirmUpload = () => {
  const { t } = useTranslation('csv');

  return (
    <VStack justifyContent="flex-start" alignItems="flex-start" width="100%">
      <Typography variant="body" color="text.default">
        {t('csv:text.newData')}
      </Typography>
      <Box bg="bg.unknown.light" borderRadius="8px" p="16px">
        <UnorderedList>
          <ListItem>
            <Typography variant="body" color="text.default">
              {t('csv:text.populate')}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body" color="text.default">
              {t('csv:text.override')}
            </Typography>
          </ListItem>
        </UnorderedList>
      </Box>
      <Typography variant="body" color="text.default">
        {t('csv:text.remember')}
      </Typography>
    </VStack>
  );
};
