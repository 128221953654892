import { useDisclosure } from '@chakra-ui/react';
import { Button } from 'Atoms/Buttons/Buttons.tsx';
import { VisibleIf } from 'Atoms/VisibleIf/VisibleIf.tsx';
import { CSVUploader } from 'Features/CSVUploader/CSVUploader.tsx';
import { t } from 'i18next';
import { UploadIcon } from 'Tokens/Icons/Function.tsx';
import { useGetCurrentCAssessment } from 'utils/hooks/General.hooks.ts';

export const FinancialsCSVUploadButton = () => {
  const { isOpen, onOpen: csvOpen, onClose } = useDisclosure();
  const { isLocked } = useGetCurrentCAssessment();

  return (
    <>
      <VisibleIf condition={!isLocked}>
        <Button
          variant="primary"
          leftIcon={<UploadIcon color="inherit" />}
          onClick={() => csvOpen()}
        >
          {t('csv:button.uploadCSV')}
        </Button>
      </VisibleIf>
      <CSVUploader
        onOpen={csvOpen}
        onClose={onClose}
        isOpen={isOpen}
        title={t('csv:title.uploadFinancial')}
      />
    </>
  );
};
