import { useUserData } from '@nhost/react';
import { useCallback, useMemo } from 'react';
import { validate } from 'uuid';
import {
  useUpsertThreadMutation,
  useRemoveCommentMutation,
  useThreadSubscription,
  Comment,
  GetEsrsMetricAnswerDocument_,
  useUpsertDatapointMdrmMutation,
  GetDatapointMdrmAnswersDocument_,
  GetMetricAnswersDocument_,
  GetCompanyLevelMdrmAnswersDocument_,
} from 'models';
import { useCurrentCompanyId } from 'utils/hooks';

export function useMdrmCommentThread({
  id,
  mdrmId,
  datapointId,
}: {
  id: string;
  mdrmId: string;
  datapointId: string;
}) {
  const user = useUserData();
  const { companyId } = useCurrentCompanyId();
  const [upsertThread] = useUpsertThreadMutation();
  const [deleteCommentMutation] = useRemoveCommentMutation();
  const { data: threadResult, loading } = useThreadSubscription({
    skip: !validate(id),
    variables: { id },
  });
  const [upsertDatapointMdrm] = useUpsertDatapointMdrmMutation();
  const thread = useMemo(() => threadResult?.thread, [threadResult]);

  const addComment = useCallback(
    (comment: string) => {
      if (!mdrmId) {
        return upsertDatapointMdrm({
          variables: {
            object: {
              datapointId: datapointId,
              mdrmRef: mdrmId,
              value: null,
              authorId: user?.id,
            },
          },
          refetchQueries: [
            GetDatapointMdrmAnswersDocument_,
            GetCompanyLevelMdrmAnswersDocument_,
            GetMetricAnswersDocument_,
          ],
        }).then((res) => {
          const mdrmId: string = res.data?.insert_esrs_DatapointMdrm_one?.id;
          const threadId: string = res.data?.insert_esrs_DatapointMdrm_one?.thread?.id;
          if (mdrmId)
            upsertThread({
              variables: {
                thread: {
                  id: threadId,
                  companyId,
                  esrsDatapointMdrmId: mdrmId,
                  comments: {
                    data: [
                      {
                        data: comment,
                        authorId: user?.id,
                      },
                    ],
                  },
                  subscribers: undefined,
                },
              },
              refetchQueries: [GetEsrsMetricAnswerDocument_, GetDatapointMdrmAnswersDocument_],
            });
        });
      }
      return upsertThread({
        variables: {
          thread: {
            id,
            companyId,
            esrsDatapointMdrmId: mdrmId,
            comments: {
              data: [
                {
                  data: comment,
                  authorId: user?.id,
                },
              ],
            },
            subscribers: undefined,
          },
        },
        refetchQueries: [GetEsrsMetricAnswerDocument_, GetDatapointMdrmAnswersDocument_],
      });
    },
    [upsertThread, thread]
  );

  const deleteComment = useCallback(
    (commentId: Comment['id']) => deleteCommentMutation({ variables: { id: commentId } }),
    [deleteCommentMutation]
  );

  return { addComment, deleteComment, thread: threadResult?.thread, loading };
}
