import { getDescendants } from '@minoru/react-dnd-treeview';
import { useBusinessUnitsSorting } from 'containers/Assessments/pieces/Assessment.hooks.ts';
import { NodeItem } from 'Features/CompanyStructure/TreeNode.tsx';
import { useBuildTree } from 'Features/CompanyStructure/useBuildTree.tsx';
import { useFinancials, CompanyFinancialResults } from 'Features/Financials/Financials.hooks.ts';
import { uniqBy, groupBy } from 'lodash';
import {
  Financials_Set_Input_,
  GetFinancialsByIdDocument_,
  useReportingGroupsQuery,
  useUpdateFinancialsByIdMutation,
} from 'models/index.ts';
import { Financials } from 'models/types.ts';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { allFinancialSections } from 'utils/financials.ts';
import { useToast } from 'utils/hooks/useToast.tsx';

export const toCSVString = (value: number | string | undefined) => {
  if (value === undefined) {
    return '';
  }
  return String(value).replace(/\"/g, '""');
};

const useOrderedTreeData = ({ financials }: { financials: any }) => {
  const { cAssessmentId } = useParams<{ cAssessmentId: string }>();
  const { sortAlphabetically, groupsFirst } = useBusinessUnitsSorting();

  const { data } = useReportingGroupsQuery({
    variables: {
      cAssessmentId: cAssessmentId,
    },
  });

  const reportingGroups = data?.reportingGroups ?? [];

  const treeData = useBuildTree(
    reportingGroups,
    financials.businessUnits.map((bu) => ({
      id: bu.id,
      businessUnit: { id: bu.businessUnit?.id ?? '', name: bu.businessUnit?.name ?? '' },
      reportingGroup: { id: bu.reportingGroupId ?? null },
      orderIndex: bu.orderIndex,
    })),
    'company',
    '',
    sortAlphabetically,
    groupsFirst
  );

  const descendants: NodeItem[] = getDescendants<NodeItem['data']>(treeData, 'company');

  const getChildren = (parentId: string, descendantsArray: NodeItem[]): NodeItem[] => {
    return descendantsArray
      .filter((d) => d.parent === parentId)
      .flatMap((node) =>
        node.data?.type === 'businessUnit' && node.parent === parentId
          ? node
          : getChildren(node.data?.original?.id, descendantsArray)
      );
  };

  const orderedTree =
    descendants
      .flatMap((node) =>
        node.data?.type === 'businessUnit' && node.parent === 'company'
          ? node
          : node.data?.type === 'reportingGroup'
            ? (getChildren(node.data?.original?.id, descendants).flat() ?? [node.text])
            : undefined
      )
      .filter((value) => value !== undefined) ?? ([] as NodeItem[]);

  return orderedTree;
};

export const useGetFinancialsDataAsCSV = () => {
  const { financials } = useFinancials({});

  const orderedTree = useOrderedTreeData({
    financials,
  });

  const orderedFinancials = uniqBy(orderedTree, 'id').map((node) => {
    return (
      financials.businessUnits.find((bu) => bu?.businessUnit?.id === node?.id) ??
      ({} as CompanyFinancialResults['businessUnits'][number])
    );
  });

  const data = [
    [
      'Reporting unit name',
      'Reporting unit labels',
      'Activity name',
      'Turnover',
      'CapEx',
      'OpEx',
      'Adaptation CapEx',
      'Adaptation OpEx',
    ],
  ];

  orderedFinancials?.forEach((bu) => {
    bu.activities.forEach((activity) =>
      data.push([
        toCSVString(bu.businessUnit?.name),
        toCSVString(bu.businessUnit?.labels.join(', ')),
        toCSVString(activity.activity?.name),
        toCSVString(activity.financials?.revenue),
        toCSVString(activity.financials?.capex),
        toCSVString(activity.financials?.opex),
        toCSVString(activity.financials?.adaptationCapex),
        toCSVString(activity.financials?.adaptationOpex),
      ])
    );
    const buNonEl = bu.financials;
    data.push([
      toCSVString(bu.businessUnit?.name),
      toCSVString(bu.businessUnit?.labels.join(', ')),
      'Taxonomy-non-eligible activities',
      toCSVString(buNonEl?.revenue ?? 0),
      toCSVString(buNonEl?.capex ?? 0),
      toCSVString(buNonEl?.opex ?? 0),
      toCSVString(buNonEl?.adaptationCapex ?? 0),
      toCSVString(buNonEl?.adaptationOpex ?? 0),
    ]);
  });
  const companyNonEl = financials.notEligibleFinancials;
  data.push([
    'Company-others-taxonomy-non-eligible',
    '',
    'Taxonomy-non-eligible activities',
    toCSVString(companyNonEl?.revenue ?? 0),
    toCSVString(companyNonEl?.capex ?? 0),
    toCSVString(companyNonEl?.opex ?? 0),
    toCSVString(companyNonEl?.adaptationCapex ?? 0),
    toCSVString(companyNonEl?.adaptationOpex ?? 0),
  ]);
  return data;
};

const IsCapexOpexValid = (data?: CSVData[], newFinancials?: CompanyFinancialResults) => {
  let capexRes = false;
  let opexRes = false;
  let invalid = false;

  const companyNonEl = newFinancials?.notEligibleFinancials;

  if (data) {
    capexRes = data.some(
      (row) => row.capex && row.adaptationCapex && row.capex < row.adaptationCapex
    );
    opexRes = data.some((row) => row.opex && row.adaptationOpex && row.opex < row.adaptationOpex);
  }
  if (newFinancials) {
    if (
      companyNonEl?.capex < companyNonEl?.adaptationCapex ||
      companyNonEl?.opex < companyNonEl?.adaptationOpex
    )
      return true;

    if (
      companyNonEl?.capex < companyNonEl?.adaptationCapex ||
      companyNonEl?.opex < companyNonEl?.adaptationOpex
    )
      return true;

    newFinancials.businessUnits.forEach((bu) => {
      if (!invalid) {
        if (
          bu.financials?.capex < bu.financials?.adaptationCapex ||
          bu.financials?.opex < bu.financials?.adaptationOpex
        )
          invalid = true;
        if (!invalid) {
          bu.activities.forEach((activity) => {
            if (
              activity.financials?.capex < activity.financials?.adaptationCapex ||
              activity.financials?.opex < activity.financials?.adaptationOpex
            )
              invalid = true;
          });
        }
        if (!invalid) {
          {
            const nonEl = bu.financials;
            if (nonEl?.capex < nonEl?.adaptationCapex || nonEl?.opex < nonEl?.adaptationOpex)
              invalid = true;
          }
        }
      }
    });
  }
  if (capexRes || opexRes || invalid) {
    return false;
  } else {
    return true;
  }
};

const checkFinancialsUpdate = (
  financialsToUpdateMap: { financialsId: string; input: Financials_Set_Input_ }[],
  newFinancials: CSVData,
  oldFinancials: Financials
) => {
  allFinancialSections.forEach((section) => {
    if (newFinancials[section] !== oldFinancials[section]) {
      const set: Financials_Set_Input_ = {
        [section]: newFinancials[section],
      };
      financialsToUpdateMap.push({ financialsId: oldFinancials.id, input: set });
    }
  });
};

const checkAllActivities = (
  financialsToUpdate: { financialsId: string; input: Financials_Set_Input_ }[],
  oldActivities: CompanyFinancialResults['businessUnits'][number]['activities'],
  newActivities: CSVData[]
) => {
  oldActivities.forEach(
    (actInFinancials: CompanyFinancialResults['businessUnits'][number]['activities'][number]) => {
      const activityInCsv = newActivities.find(
        (actInCSV: CSVData[][number]) =>
          actInCSV.activityName.toLowerCase() === actInFinancials.activity?.name.toLowerCase()
      );
      if (activityInCsv && actInFinancials?.financials) {
        checkFinancialsUpdate(financialsToUpdate, activityInCsv, actInFinancials.financials);
      }
    }
  );
};

const getUpdatedFinancialsMap = (
  groupByRU: { [key: string]: CSVData[] },
  financials: CompanyFinancialResults
) => {
  const financialsToUpdate: { financialsId: string; input: Financials_Set_Input_ }[] = [];
  Object.entries(groupByRU).forEach(([buName, activities]) => {
    const buOldFinancials = financials.businessUnits.find(
      (bu: CompanyFinancialResults['businessUnits'][number]) => bu.businessUnit?.name === buName
    );
    if (buOldFinancials) {
      const notEligibleBU = activities.find(
        (row) => row.activityName.toLowerCase() === 'taxonomy-non-eligible activities'
      );
      const oldNotEligibleBU = buOldFinancials?.financials;
      if (notEligibleBU && !!oldNotEligibleBU) {
        checkFinancialsUpdate(financialsToUpdate, notEligibleBU, oldNotEligibleBU);
      }

      checkAllActivities(financialsToUpdate, buOldFinancials?.activities, activities);
    } else {
      if (buName === 'Company-others-taxonomy-non-eligible' && !!financials.notEligibleFinancials) {
        checkFinancialsUpdate(financialsToUpdate, activities[0], financials.notEligibleFinancials);
      }
    }
  });
  return financialsToUpdate;
};
export const useUploadCSVFinancials = () => {
  const { cAssessmentId } = useParams<{ cAssessmentId: string }>();
  const { financials } = useFinancials({});
  const [updateFinancials] = useUpdateFinancialsByIdMutation();
  const { t } = useTranslation();
  const toast = useToast();

  const onUploadDone = useCallback(
    async (rows: CSVData[]) => {
      const groupByRU = groupBy(rows, 'reportingUnitName');

      if (!IsCapexOpexValid(rows)) {
        toast({
          text: t('csv:error.capexOpex'),
          variant: 'danger',
          closable: true,
          duration: null,
        });
        return;
      }

      const updatedFinancialsMap = getUpdatedFinancialsMap(groupByRU, financials);

      try {
        await Promise.all(
          updatedFinancialsMap.map((update) =>
            updateFinancials({
              refetchQueries: [GetFinancialsByIdDocument_],
              variables: {
                id: update.financialsId,
                _set: update.input,
              },
            })
          )
        );

        window.location.reload();
      } catch (error) {
        console.error('Error updating financials:', error);

        toast({
          text: t('csv:toast.uploadError'),
          variant: 'danger',
          closable: true,
        });
      }
    },
    [financials, t, toast, cAssessmentId, updateFinancials]
  );

  return { onUploadDone };
};
