import { Tbody, Tr, Td, Box, Table as ChakraTable, VStack, HStack } from '@chakra-ui/react';
import { AutoResizeTextarea, Button, IconButton, Tooltip } from 'Atoms';
import { Typography } from 'Tokens';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { ArrowNarrowRightIcon, CornerDownRightIcon } from 'Tokens/Icons/Direction';
import { UserAvatar } from 'Organisms';
import { AddUserIcon, CommentIcon } from 'Tokens/Icons/Function';
import {
  MDRM_QUESTIONS,
  MdrmAnswers,
  MdrmAnswerType,
  MdrmType,
  MdrmTypeEnum,
  OnMdrmAnswerChangeType,
} from './MDRM.types';
import { MetricsTableData } from '../MetricAnswers.hooks';
import { useSearchParams } from 'react-router-dom';
import { useNotifications } from 'Features';
import { DescriptionIcon } from 'Tokens/Icons/Data';
import { AggregatedQualitativeAnswers } from '../AggregatedMetrics';
import { User } from 'models';

const MdrmInput = ({
  mdrmId,
  onMdrmAnswerChange,
  answer,
}: {
  mdrmId: MdrmType;
  answer?: MdrmAnswerType;
  onMdrmAnswerChange: OnMdrmAnswerChangeType;
}) => {
  const [localValue, setLocalValue] = useState<string>(answer?.value ?? '');
  const [hasStartedTyping, setHasStartedTyping] = useState(false);

  useEffect(() => {
    if (!hasStartedTyping) setLocalValue(answer?.value ?? '');
  }, [answer]);

  const debounceSave = useCallback(
    debounce((newValue) => {
      onMdrmAnswerChange(newValue ?? '', mdrmId);
    }, 500),
    [mdrmId, onMdrmAnswerChange]
  );

  const handleChange = (val: string) => {
    setHasStartedTyping(true);
    setLocalValue(val);
    debounceSave(val);
  };

  return (
    <AutoResizeTextarea
      variant="ghost"
      minH="56px"
      onChange={({ target }) => handleChange(target.value)}
      placeholder="Write your answer"
      value={localValue ?? ''}
      borderRadius="6px"
      lineHeight="20px"
      size="md"
      isDisabled={answer?.hasOptedOut}
    />
  );
};

const CommentsCounter = ({
  mdrm,
  onClick,
}: {
  mdrm: MdrmAnswerType | undefined;
  onClick: () => void;
}) => {
  const { notifications } = useNotifications();

  const commentsCount = useMemo(
    () => mdrm?.thread?.comments_aggregate.aggregate?.count ?? 0,
    [mdrm]
  );

  const hasUnread = useMemo(
    () =>
      notifications.some(
        (notf) => notf.comment.thread.esrsDatapointMdrm?.id === mdrm?.id && !notf.isRead
      ),
    [notifications, mdrm]
  );

  return (
    <Button
      size="sm"
      variant="ghost"
      leftIcon={<CommentIcon color={hasUnread ? 'text.action' : 'text.muted'} />}
      onClick={onClick}
    >
      <Typography
        variant="bodyStrong"
        color={hasUnread ? 'text.action' : 'text.muted'}
        opacity={commentsCount === 0 ? 0.2 : 1}
      >
        {commentsCount}
      </Typography>
    </Button>
  );
};

export const MDRMTable = ({
  metric,
  answers,
  onMdrmAnswerChange,
  setOpenMdrm,
  setOpenComments,
  setRowData,
}: {
  metric: MetricsTableData;
  answers: MdrmAnswers;
  onMdrmAnswerChange: OnMdrmAnswerChangeType;
  setOpenMdrm: (open: MdrmAnswerType | undefined) => void;
  setOpenComments: (open: boolean) => void;
  setRowData: (
    param: (MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }) | undefined
  ) => void;
}) => {
  const isEntitySpecific = useMemo(
    () =>
      metric.metric.additionalTypes.some((type) => {
        return type.additionalType.reference === 'Entity-specific';
      }),
    [metric.metric.additionalTypes]
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const openMdrmId = useMemo(() => {
    return searchParams.get('openMdrm');
  }, [searchParams]);

  const handleRowClick = (row: MdrmAnswerType | undefined, isComments: boolean) => {
    setOpenMdrm(row);
    setRowData(undefined);
    setOpenComments(isComments);
    // Add a param to the url to tell that the mdrm sidebar is open
    setSearchParams({ openMdrm: row?.id ?? '' });
  };

  const mappedMdrms = useMemo(() => {
    return MDRM_QUESTIONS.filter((q) => {
      if (q.id === MdrmTypeEnum.entitySpecific) {
        return isEntitySpecific;
      }
      return true;
    }).map((q) => {
      return {
        id: q.id,
        question: q.question,
        answer: answers[q.id],
      };
    });
  }, [answers, isEntitySpecific]);

  // Check if there is an openMdrm in the url, then set the openMdrm state to thr corresponding one
  useEffect(() => {
    if (openMdrmId) {
      const mdrm = mappedMdrms.find((mdrm) => mdrm.answer?.id === openMdrmId);
      if (mdrm) {
        setOpenMdrm(mdrm.answer);
      }
    }
  }, [openMdrmId, mappedMdrms]);

  return (
    <VStack w="100%" spacing="10px" alignItems="start">
      <HStack spacing="6px">
        <CornerDownRightIcon />
        <Typography variant="h4">MDR-M for {metric.metric.title}</Typography>
      </HStack>
      <ChakraTable
        borderTop="1px solid"
        borderColor="border.decorative"
        borderRadius="8px"
        w="100%"
      >
        <Tbody>
          {mappedMdrms.map((row, index, arr) => {
            const answer = row.answer;
            const hasOptedOut = answer?.hasOptedOut;
            return (
              <Tr
                key={row.id}
                borderColor="border.decorative"
                height="48px"
                alignItems="start"
                bg={answer?.id === openMdrmId ? 'bg.hover' : ''}
                _hover={{
                  transition: '0.15s ease-in-out',
                  bg: 'bg.hover',
                }}
              >
                <Td
                  w="50%"
                  padding="14px 8px"
                  verticalAlign="top"
                  borderColor="border.decorative"
                  borderBottom={index === arr.length - 1 ? 'none' : undefined}
                >
                  <HStack
                    w="100%"
                    alignItems="start"
                    className="metricTitle"
                    textDecoration="underline"
                    textDecorationColor={answer?.id === openMdrmId ? 'text.hint' : 'transparent'}
                    textUnderlineOffset={answer?.id === openMdrmId ? '2px' : '4px'}
                    transition="0.15s"
                    spacing="8px"
                    cursor="pointer"
                    onClick={() => handleRowClick(answer, false)}
                    _hover={{
                      textDecoration: 'underline',
                      textDecorationColor: 'text.hint',
                      textUnderlineOffset: '2px',
                    }}
                  >
                    <Tooltip label="MDR-M" placement="left">
                      <Box>
                        <DescriptionIcon color={hasOptedOut ? 'text.hint' : ''} />
                      </Box>
                    </Tooltip>
                    <Typography variant="body" color={hasOptedOut ? 'text.hint' : ''}>
                      {row.question}
                    </Typography>
                  </HStack>
                </Td>
                <Td
                  padding="6px 8px"
                  verticalAlign="top"
                  borderColor="border.decorative"
                  borderBottom={index === arr.length - 1 ? 'none' : undefined}
                >
                  <Box w="100%">
                    <MdrmInput
                      mdrmId={row.id}
                      answer={answer}
                      onMdrmAnswerChange={onMdrmAnswerChange}
                    />
                  </Box>
                </Td>
                <Td
                  w="54px"
                  padding="14px 8px"
                  verticalAlign="top"
                  borderColor="border.decorative"
                  borderBottom={index === arr.length - 1 ? 'none' : undefined}
                >
                  {row.answer?.author ? (
                    <UserAvatar
                      user={answer?.author as Pick<User, 'displayName' | 'avatarUrl'>}
                      size="xs"
                    />
                  ) : (
                    <Box bg="bg.muted" boxSize="20px" borderRadius="50%" padding="2px">
                      <AddUserIcon color="text.default" />
                    </Box>
                  )}
                </Td>
                <Td
                  w="86px"
                  padding="10px 6px"
                  verticalAlign="top"
                  borderColor="border.decorative"
                  borderBottom={index === arr.length - 1 ? 'none' : undefined}
                >
                  <HStack gap="2px">
                    <CommentsCounter mdrm={answer} onClick={() => handleRowClick(answer, true)} />
                    <IconButton
                      className="metricArrow"
                      aria-label="side-bar"
                      size="sm"
                      variant="ghost"
                      icon={<ArrowNarrowRightIcon />}
                      onClick={() => handleRowClick(answer, false)}
                    />
                  </HStack>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </ChakraTable>
    </VStack>
  );
};
