import {
  Bookmark,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  VerticalAlign,
  WidthType,
} from 'docx';
import { EsrsReportMetric } from '../../Report.types';
import { TextStyle, tableBorders } from '../reportThemes';

const createRows = (dataRows: { title: string; answer: string; reference: string }[]) => {
  return dataRows.map((rowData) => {
    return new TableRow({
      children: [
        new TableCell({
          verticalAlign: VerticalAlign.TOP,
          children: [
            new Paragraph({
              children: [
                new Bookmark({
                  id: rowData.reference,
                  children: [
                    new TextRun({
                      text: rowData.title,
                    }),
                  ],
                }),
              ],
              style: TextStyle.body,
            }),
          ],
        }),
        new TableCell({
          verticalAlign: VerticalAlign.TOP,
          children: [
            new Paragraph({
              text: rowData.answer,
              style: TextStyle.body,
            }),
          ],
        }),
      ],
    });
  });
};

export const createGOV4Table = (metrics: EsrsReportMetric[]) => {
  const dataRows = metrics.flatMap((metric) => {
    const metricInfo = metric.metric.metric;
    const title = metricInfo.shortTitle ?? metricInfo.title;
    const answer = metric.textAnswer ?? '';
    const reference = metricInfo.reference;
    return { title, answer, reference };
  });
  const tableContentRows = createRows(dataRows);

  const headerRow = new TableRow({
    tableHeader: true,
    children: ['Core elements of due diligence', 'Paragraphs in the sustainability statement'].map(
      (header) =>
        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          width: { size: '50%', type: WidthType.PERCENTAGE },
          children: [new Paragraph({ text: header, style: TextStyle.tableTitle })],
        })
    ),
  });

  return new Table({
    columnWidths: [5000, 5000],
    margins: { top: 60, bottom: 60, right: 60, left: 60 },
    width: { size: '100%', type: WidthType.PERCENTAGE },
    borders: tableBorders,
    rows: [headerRow, ...tableContentRows],
  });
};
