import { HStack, Skeleton } from '@chakra-ui/react';
import { NumberInput } from 'Atoms/index.ts';
import { Tooltip } from 'Atoms/Tooltip/Tooltip.tsx';
import { Typography } from 'Tokens/index.ts';
import { useGetFinancialInputData } from './FinancialInput.hooks.ts';
import { useEffect, useMemo, useRef, useState } from 'react';
import { WarningIcon } from 'Tokens/Icons/Status.tsx';
import { LockedIcon } from 'Tokens/Icons/Function.tsx';
import { useTranslation } from 'utils/translation/translation.ts';

export const FinancialInput = ({
  section,
  financialsId,
}: {
  section: string;
  financialsId: string;
}) => {
  const FINANCIAL_SAVE_DELAY_MS = 1000;
  const { t } = useTranslation(['financials']);

  const [financialNumber, setFinancialNumber] = useState<number>(0);
  const [initialLoad, setInitialLoad] = useState(true);
  const [localLatestNumber, setLocalLatestNumber] = useState<number>();
  const [isPasting, setIsPasting] = useState<number | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    value,
    errorMessage,
    isDisabled,
    isAdaptationLocked,
    isLocked,
    onChange,
    loading,
    resetErrorMessage,
  } = useGetFinancialInputData({
    section,
    financialsId,
  });

  useEffect(() => {
    if (errorMessage !== undefined) {
      setFinancialNumber(value);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (initialLoad) {
      if (value !== undefined) {
        setLocalLatestNumber(value);
        setFinancialNumber(value);
        setInitialLoad(false);
      }
    }
  }, [value]);

  useEffect(() => {
    if (financialNumber !== localLatestNumber) {
      const debounceSave = setTimeout(() => {
        setLocalLatestNumber(financialNumber);
        onChange(financialNumber);
      }, FINANCIAL_SAVE_DELAY_MS);

      return () => clearTimeout(debounceSave);
    }
  }, [financialNumber]);

  const leftIcon = useMemo(() => {
    if (errorMessage) {
      return <WarningIcon color="text.warning" />;
    } else {
      return undefined;
    }
  }, [isAdaptationLocked, errorMessage]);

  const tooltipLabel = useMemo(() => {
    // if (isAdaptationLocked) {
    //   return t('financials:adaptation.tooltip');
    // }
    return errorMessage;
  }, [isAdaptationLocked, errorMessage]);

  if (isLocked) {
    return <Typography marginRight="15px">{value?.toLocaleString()}</Typography>;
  }

  return (
    <Tooltip
      backgroundColor={errorMessage ? 'bg.critical.accent' : undefined}
      placement="top"
      label={tooltipLabel}
      hasArrow
      defaultIsOpen={errorMessage ? true : false}
      closeDelay={errorMessage ? 500 : undefined}
    >
      <Skeleton isLoaded={initialLoad === false}>
        <HStack width="100%">
          <NumberInput
            ref={inputRef}
            key={`${financialsId}-${section}`}
            id={`${financialsId}-${section}`}
            width="100%"
            value={financialNumber}
            isInvalid={errorMessage !== undefined}
            isDisabled={isDisabled}
            onClick={resetErrorMessage}
            onChange={(val) => {
              const value = val.toString();

              if (isPasting) {
                setFinancialNumber(val);
                setIsPasting(undefined);
              } else {
                if (financialNumber === 0 && value.length > 1 && value.endsWith('0')) {
                  setFinancialNumber(Number(value.slice(0, -1)));
                } else {
                  setFinancialNumber(val);
                }
              }
            }}
            format={(value) => {
              return value.toLocaleString();
            }}
            onBlur={() => {
              if (financialNumber !== localLatestNumber) {
                onChange(financialNumber);
              }
            }}
            replaceNanWithZero={true}
            loading={loading}
            leftIcon={leftIcon}
            onPaste={() => {
              const input = inputRef.current;
              const cursorPosition = input?.selectionStart;
              setIsPasting(cursorPosition ?? undefined);
            }}
          />
        </HStack>
      </Skeleton>
    </Tooltip>
  );
};
