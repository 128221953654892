import { TAG_STATUS } from 'Molecules/ObjectivesTiles';
import { Typography } from 'Tokens';
import { useKnowledgeBase } from 'Features';
import { useTranslation } from 'utils/translation';
import { objectiveExpressionTag } from './Activities.hooks';
import { ARTICLE_ENABLING_TRANSITIONAL_EXCLUSIONS } from 'Features/KnowledgeBase/KnowledgeBaseArticles';

const ObjectiveDescriptionText = ({ textKey, slug }: { textKey: string; slug: string }) => {
  const { t } = useTranslation('assessment');
  const { onOpen } = useKnowledgeBase();

  return (
    <Typography variant="body" color="text.default">
      {t(textKey)}{' '}
      <Typography as="span" onClick={() => onOpen(slug)} color="text.selected" cursor="pointer">
        {t('assessment:activities.disclaimer.readMore')}
      </Typography>
    </Typography>
  );
};

export const ObjectiveDescription = ({ tagExpression }: { tagExpression: string | undefined }) => {
  const tagExpressionValue = objectiveExpressionTag(tagExpression);

  const isEnabling =
    tagExpressionValue === TAG_STATUS.Enabling.status ||
    tagExpressionValue === TAG_STATUS.CanBeEnabling.status;

  const isTransitional =
    tagExpressionValue === TAG_STATUS.Transitional.status ||
    tagExpressionValue === TAG_STATUS.CanBeTransitional.status;

  if (isEnabling) {
    return (
      <ObjectiveDescriptionText
        textKey="assessment:activities.disclaimer.enabling"
        slug={ARTICLE_ENABLING_TRANSITIONAL_EXCLUSIONS.slug}
      />
    );
  }

  if (isTransitional) {
    return (
      <ObjectiveDescriptionText
        textKey="assessment:activities.disclaimer.transitional"
        slug={ARTICLE_ENABLING_TRANSITIONAL_EXCLUSIONS.slug}
      />
    );
  }

  return null;
};
