import { BorderStyle } from 'docx';

export enum Colors {
  white = '#FFFFFF',
  default = '#000000',
  muted = '#0000B2',
  lightGray = '#E1E1E6',
  gray = '#707073',
}

export enum Fonts {
  default = 'Manrope',
}

export enum FontSizes {
  h1 = 36,
  h2 = 32,
  h3 = 28,
  h4 = 24,
  body = 24,
  title = 40,
}

export enum TextStyle {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  body = 'body',
  title = 'title',
  tableTitle = 'tableTitle',
  bodyItalic = 'bodyItalic',
  bodyBold = 'bodyBold',
}

export const styles = {
  paragraphStyles: [
    {
      id: TextStyle.h1,
      name: 'Heading 1',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.h1,
        bold: true,
        color: Colors.default,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.h2,
      name: 'Heading 2',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.h2,
        bold: true,
        color: Colors.default,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.h3,
      name: 'Heading 3',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.h3,
        bold: true,
        color: Colors.default,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.h4,
      name: 'Heading 4',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.h4,
        bold: true,
        color: Colors.default,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.title,
      name: 'Title',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.title,
        bold: true,
        color: Colors.default,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.body,
      name: 'Body',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.body,
        color: Colors.default,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.tableTitle,
      name: 'Table Title',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.body,
        color: Colors.gray,
        bold: true,
        font: Fonts.default,
      },
    },
    {
      id: TextStyle.bodyItalic,
      name: 'Body Italic',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.body,
        color: Colors.default,
        font: Fonts.default,
        italics: true,
      },
    },
    {
      id: TextStyle.bodyBold,
      name: 'Body Bold',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.body,
        color: Colors.default,
        font: Fonts.default,
        bold: true,
      },
    },
    {
      id: TextStyle.h5,
      name: 'Heading 5',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: FontSizes.body,
        bold: true,
        color: Colors.default,
        font: Fonts.default,
      },
    },
  ],
};

export const tableBorders = {
  top: {
    style: BorderStyle.NONE,
  },
  left: {
    style: BorderStyle.NONE,
  },
  right: {
    style: BorderStyle.NONE,
  },
  bottom: {
    style: BorderStyle.NONE,
  },
  insideHorizontal: {
    style: BorderStyle.THICK,
    color: Colors.lightGray,
    size: 6,
  },
  insideVertical: {
    style: BorderStyle.THICK,
    color: Colors.lightGray,
    size: 6,
  },
};
