import { businessUnitsSorter } from 'containers/Assessments/pieces/Assessment.hooks';
import { useGetFinancialsQuery, GetFinancialsQuery_, Financials, BareFinancials } from 'models';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { aggregateFinancials, createBareFinancials } from 'utils/financials';

export type CompanyFinancialResults = {
  notEligibleFinancials?: GetFinancialsQuery_['notEligibleFinancials'][0];
  comparisonTotalFinancials?: GetFinancialsQuery_['comparisonTotalFinancials'][0] | null;
  businessUnits: Array<{
    id: string;
    reportingGroupId?: string;
    orderIndex: string;
    businessUnit: GetFinancialsQuery_['businessUnits'][0]['businessUnit'];
    financials: GetFinancialsQuery_['businessUnits'][0]['businessUnitFinancials'][0]['financials'];
    activities: Array<{
      activity?: GetFinancialsQuery_['businessUnits'][0]['activities'][0]['activity'];
      financials?: GetFinancialsQuery_['businessUnits'][0]['activities'][0]['financials'];
      id?: string;
      bAssessmentId?: string;
    }>;
  }>;
  totalNotEligibleFinancials: BareFinancials;
  totalEligibleFinancials: BareFinancials;
};

export const useFinancials = ({
  cAssessmentId,
  sortAlphabetically,
  groupsFirst,
}: {
  cAssessmentId?: string;
  sortAlphabetically?: boolean;
  groupsFirst?: boolean;
}) => {
  const { cAssessmentId: cAssessmentIdFromParams } = useParams<{ cAssessmentId: string }>();
  const { data, ...rest } = useGetFinancialsQuery({
    variables: {
      cId: cAssessmentId ?? cAssessmentIdFromParams,
    },
    skip: !cAssessmentId && !cAssessmentIdFromParams,
  });
  console.log('data', data);

  const financials: CompanyFinancialResults = useMemo(() => {
    const companyLevelNotEligibleFinancials = data?.notEligibleFinancials[0];
    const comparisonTotalFinancials = data?.comparisonTotalFinancials?.[0] ?? null;
    const businessUnits =
      data?.businessUnits.map((bu) => ({
        id: bu.id,
        businessUnit: bu?.businessUnit,
        reportingGroupId: bu?.reportingGroupId,
        orderIndex: bu.orderIndex,
        financials:
          bu?.businessUnitFinancials?.[0]?.financials ??
          (createBareFinancials(
            0,
            0,
            0,
            0
          ) as CompanyFinancialResults['businessUnits'][0]['financials']),
        activities: bu?.activities.map((act) => ({
          activity: act.activity,
          financials:
            act.financials ??
            (createBareFinancials(
              0,
              0,
              0,
              0
            ) as CompanyFinancialResults['businessUnits'][0]['activities'][0]['financials']),
          id: act?.id,
          bAssessmentId: act?.bAssessmentId,
        })),
      })) ?? [];

    const allActivities = businessUnits.map((bu) => bu.activities).flat();
    const activityFinancials = allActivities.map((act) => ({
      ...(act.financials as Financials),
    }));

    const totalEligibleFinancials = aggregateFinancials(activityFinancials);

    const reportingUnitNotEligibleFinancials = businessUnits.map(
      (bu) => bu.financials ?? createBareFinancials()
    );
    const totalNotEligibleFinancials = aggregateFinancials([
      ...reportingUnitNotEligibleFinancials,
      companyLevelNotEligibleFinancials ?? createBareFinancials(),
    ]);

    if (sortAlphabetically) {
      businessUnits.sort((a, b) =>
        businessUnitsSorter(a.businessUnit?.name ?? '', b.businessUnit?.name ?? '')
      );
    }

    console.log('comparisonTotalFinancials', comparisonTotalFinancials);

    return {
      notEligibleFinancials: companyLevelNotEligibleFinancials,
      businessUnits,
      comparisonTotalFinancials,
      totalEligibleFinancials,
      totalNotEligibleFinancials,
    };
  }, [data, sortAlphabetically, groupsFirst]);

  return { financials, ...rest };
};
