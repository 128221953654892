import { useMemo, useRef } from 'react';
import { DRQAnswersType, DRQuestionsType } from './DRQuestionTable';
import { BooleanSelector } from 'Molecules';
import { VStack } from '@chakra-ui/react';
import { AutoResizeTextarea } from 'Atoms';
import { ESRSInputArticle } from 'containers/Esrs/EsrsUtilComponents';

export const BooleanInputAnswer = ({
  answers,
  setAnswers,
  question,
}: {
  answers: DRQAnswersType;
  setAnswers: (params: DRQAnswersType) => void;
  question: DRQuestionsType[number];
}) => {
  const answer = useMemo(() => answers?.[question.reference], [answers]);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleChangeBooleanAnswer = (val: boolean | undefined) => {
    setAnswers({
      ...answers,
      [question.reference]: {
        factValue: val,
        value: answer?.value,
      },
    });
  };

  const handleChangeTextAnswer = (val: string) => {
    setAnswers({
      ...answers,
      [question.reference]: {
        factValue: answer?.factValue,
        value: val,
      },
    });
  };

  return (
    <VStack alignItems="start">
      <BooleanSelector
        answer={answer?.factValue}
        handleChangeAnswer={handleChangeBooleanAnswer}
        extra={<ESRSInputArticle type="boolean" />}
      />
      <AutoResizeTextarea
        variant="ghost"
        ref={textareaRef}
        borderRadius="6px"
        lineHeight="20px"
        size="md"
        placeholder="Write your answer"
        value={answer?.value ?? ''}
        onChange={({ target }) => {
          handleChangeTextAnswer(target.value);
        }}
        onClick={(e) => e.stopPropagation()}
      />
    </VStack>
  );
};
