import { HStack, Menu, MenuButton, MenuList, MenuItem as ChakraMenuItem } from '@chakra-ui/react';
import { Button, ButtonVariant } from 'Atoms';
import { Typography } from 'Tokens';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { CheckIcon } from 'Tokens/Icons/Status';
import { useMemo } from 'react';

export enum BooleanEnum {
  True = 'True',
  False = 'False',
  Select = 'Select',
}

const MenuItem = ({
  text,
  isSelected,
  handleChangeAnswer,
}: {
  text: BooleanEnum;
  isSelected: boolean;
  handleChangeAnswer: (param: boolean | undefined) => void;
}) => {
  const answer = useMemo(() => {
    if (isSelected) {
      return undefined;
    } else {
      return text === BooleanEnum.True ? true : text === BooleanEnum.False ? false : undefined;
    }
  }, [isSelected, text]);

  return (
    <ChakraMenuItem onClick={() => handleChangeAnswer(answer)}>
      <HStack justifyContent="space-between" w="100%">
        <Typography variant="bodyStrong" color={isSelected ? 'text.selected' : 'text.muted'}>
          {text}
        </Typography>
        {isSelected && <CheckIcon color="text.selected" />}
      </HStack>
    </ChakraMenuItem>
  );
};

export const BooleanSelector = ({
  answer,
  handleChangeAnswer,
  variant = 'ghost',
  alignVertically = true,
  isDisabled = false,
  extra,
}: {
  answer?: boolean;
  handleChangeAnswer: (param: boolean | undefined) => void;
  variant?: ButtonVariant;
  alignVertically?: boolean;
  isDisabled?: boolean;
  extra?: React.ReactNode;
}) => {
  const answerText = useMemo(
    () => (answer === true ? BooleanEnum.True : answer === false ? BooleanEnum.False : 'Select'),
    [answer]
  );
  return (
    <Menu>
      <MenuButton
        as={Button}
        variant={variant}
        rightIcon={<ChevronDownIcon color="text.muted" boxSize="16px" />}
        size="sm"
        alignVertically={alignVertically}
        isDisabled={isDisabled}
      >
        {answerText}
      </MenuButton>
      <MenuList padding="8px" width="230px">
        {extra}
        <MenuItem
          text={BooleanEnum.True}
          isSelected={answer === true}
          handleChangeAnswer={handleChangeAnswer}
        />
        <MenuItem
          text={BooleanEnum.False}
          isSelected={answer === false}
          handleChangeAnswer={handleChangeAnswer}
        />
      </MenuList>
    </Menu>
  );
};
