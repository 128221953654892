import { uniq } from 'lodash';
import { Paragraph, Table, TableCell, TableRow, VerticalAlign, WidthType } from 'docx';
import { EsrsReportData, EsrsReportMetric } from '../../Report.types';
import { TextStyle, tableBorders } from '../reportThemes';
import { TargetFieldsFragment_ } from 'models';
import { percentageToNumber } from 'containers/Esrs/utils';
import { findMetricRow } from '../report-helper-functions';

type RowData = {
  annual: string;
  title: string;
  baseYear: string;
  ratio: string;
  answer: string;
  [key: number]: string;
}[];

export const E1_6TargetMetrics = [
  {
    reference: 'grossScopeGHG1',
    title: 'Gross Scope 1 GHG emissions (tCO2eq)',
  },
  {
    reference: 'percentageScope1TradingSchemes',
    title: 'Percentage of Scope 1 GHG emissions from regulated emission trading schemes (%)',
  },
  {
    reference: 'grossScopeGHG2LocationBased',
    title: 'Gross location-based Scope 2 GHG emissions (tCO2eq)',
  },
  {
    reference: 'grossScopeGHG2MarketBased',
    title: 'Gross market-based Scope 2 GHG emissions (tCO2eq)',
  },
  {
    reference: 'grossScopeGHG3',
    title: 'Total Gross indirect (Scope 3) GHG emissions (tCO2eq)',
  },
  {
    reference: 'scope3Cat1',
    title: '1 Purchased goods and services',
  },
  {
    reference: 'scope3Cat1sub',
    title: '(Optional sub-category): Cloud computing and data centre services',
  },
  {
    reference: 'scope3Cat2',
    title: '2 Capital goods',
  },
  {
    reference: 'scope3Cat3',
    title: '3 Fuel and energy-related Activities (not included in Scope 1 or Scope 2)',
  },
  {
    reference: 'scope3Cat4',
    title: '4 Upstream transportation and distribution',
  },
  {
    reference: 'scope3Cat5',
    title: '5 Waste generated in operations',
  },
  {
    reference: 'scope3Cat6',
    title: '6 Business traveling',
  },
  {
    reference: 'scope3Cat7',
    title: '7 Employee commuting',
  },
  {
    reference: 'scope3Cat8',
    title: '8 Upstream leased assets',
  },
  {
    reference: 'scope3Cat9',
    title: '9 Downstream transportation',
  },
  {
    reference: 'scope3Cat10',
    title: '10 Processing of sold products',
  },
  {
    reference: 'scope3Cat11',
    title: '11 Use of sold products',
  },
  {
    reference: 'scope3Cat12',
    title: '12 End-of-life treatment of sold products',
  },
  {
    reference: 'scope3Cat13',
    title: '13 Downstream leased assets',
  },
  {
    reference: 'scope3Cat14',
    title: '14 Franchises',
  },
  {
    reference: 'scope3Cat15',
    title: '15 Investments',
  },
  {
    reference: 'totalGHGLocationBased',
    title: 'Total GHG emissions (location-based) (tCO2eq)',
  },
  {
    reference: 'totalGHGDerivedScope2MarketBased',
    title: 'Total GHG emissions (market-based) (tCO2eq)',
  },
];

const createRows = (rowData: RowData, milestoneYears: number[]) => {
  return rowData.map((rowData) => {
    return new TableRow({
      children: [
        new TableCell({
          verticalAlign: VerticalAlign.TOP,
          children: [new Paragraph({ text: rowData.title, style: TextStyle.body })],
        }),
        new TableCell({
          verticalAlign: VerticalAlign.TOP,
          children: [
            new Paragraph({
              text: rowData.baseYear,
              style: TextStyle.body,
            }),
          ],
        }),
        new TableCell({
          verticalAlign: VerticalAlign.TOP,
          children: [
            new Paragraph({
              text: rowData.answer,
              style: TextStyle.body,
            }),
          ],
        }),
        new TableCell({
          verticalAlign: VerticalAlign.TOP,
          children: [
            new Paragraph({
              text: rowData.ratio,
              style: TextStyle.body,
            }),
          ],
        }),
        ...milestoneYears.map(
          (year) =>
            new TableCell({
              verticalAlign: VerticalAlign.TOP,
              children: [
                new Paragraph({
                  text: rowData?.[year],
                  style: TextStyle.body,
                }),
              ],
            })
        ),
        new TableCell({
          verticalAlign: VerticalAlign.TOP,
          children: [
            new Paragraph({
              text: rowData.annual,
              style: TextStyle.body,
            }),
          ],
        }),
      ],
    });
  });
};

const createHeaderRow = (milestoneYears: number[], columnsNb: number, year: number) =>
  new TableRow({
    tableHeader: true,
    children: [
      new TableCell({
        verticalAlign: VerticalAlign.CENTER,
        width: { size: '20%', type: WidthType.PERCENTAGE },
        children: [new Paragraph({ text: '', style: TextStyle.tableTitle })],
      }),
      new TableCell({
        verticalAlign: VerticalAlign.CENTER,
        width: { size: `${columnsNb}%`, type: WidthType.PERCENTAGE },
        children: [new Paragraph({ text: `Base year`, style: TextStyle.tableTitle })],
      }),
      new TableCell({
        verticalAlign: VerticalAlign.CENTER,
        width: { size: `${columnsNb}%`, type: WidthType.PERCENTAGE },
        children: [new Paragraph({ text: `${year}`, style: TextStyle.tableTitle })],
      }),
      new TableCell({
        verticalAlign: VerticalAlign.CENTER,
        width: { size: `${columnsNb}%`, type: WidthType.PERCENTAGE },
        children: [new Paragraph({ text: '% N/baseline', style: TextStyle.tableTitle })],
      }),
      ...milestoneYears.map(
        (year) =>
          new TableCell({
            verticalAlign: VerticalAlign.CENTER,
            width: { size: `${columnsNb}%`, type: WidthType.PERCENTAGE },
            children: [new Paragraph({ text: `${year}`, style: TextStyle.tableTitle })],
          })
      ),
      new TableCell({
        verticalAlign: VerticalAlign.CENTER,
        width: { size: `${columnsNb}%`, type: WidthType.PERCENTAGE },
        children: [
          new Paragraph({ text: `Annual% target / Base year`, style: TextStyle.tableTitle }),
        ],
      }),
    ],
  });

export const createE16TargetsTable = (
  targets: TargetFieldsFragment_[] | undefined,
  metrics: EsrsReportMetric[],
  year: number,
  materialStandards: EsrsReportData['materialStandards']
) => {
  const relevantTargets = targets?.filter((target) =>
    E1_6TargetMetrics.some((m) => m.reference === target.metricRef)
  );

  const materialMetrics =
    materialStandards
      ?.find((s) => s.standardRef === 'ESRS-E1' && s.isMaterial)
      ?.materialMetrics.filter((mm) => mm.isMaterial) ?? [];
  const availableMetrics = E1_6TargetMetrics.filter((m) =>
    materialMetrics.some((mm) => mm.metricRef === m.reference)
  );

  const milestoneYears = uniq(
    relevantTargets?.flatMap((tr) => tr.milestones.flatMap((m) => m.year)) ?? []
  ).sort((a, b) => a - b);
  const columnsNb = (milestoneYears?.length + 4) / 80;
  const columnWidth = Array(milestoneYears?.length + 4).fill(
    Math.floor(8000 / (milestoneYears?.length + 4))
  );

  const dataRows = availableMetrics?.flatMap((metric) => {
    const target = targets?.find((t) => t.metricRef === metric.reference);
    const baseline = target?.keyResults?.find((kr) => kr.reportingUnitId === null);

    const milestones = milestoneYears.reduce(
      (acc, key) => {
        const milestone = target?.milestones.find((milestone) => milestone.year === key);
        const milestoneResult = milestone?.milestoneResults.find(
          (mr) => mr.reportingUnitId === null
        )?.value;
        acc[key] =
          milestoneResult !== undefined && milestoneResult !== null
            ? `${percentageToNumber(milestoneResult, Number(baseline?.baseline))}`
            : '';
        return acc;
      },
      {} as { [key: number]: string }
    );
    const rows = metrics.map((m) => m.tableData);
    const metricRow = findMetricRow(metric, rows);
    const inputAnswer = metricRow?.result?.Year;
    const baseYear = baseline?.baseYear ? new Date(baseline.baseYear).getFullYear().toString() : '';
    const ratio = !inputAnswer
      ? ''
      : !baseline?.baseline
        ? ''
        : `${parseFloat((inputAnswer / Number(baseline?.baseline)).toFixed(2))}`;
    const answer = !inputAnswer ? '' : String(inputAnswer);
    const biggestMilestoneYear = Math.max(
      ...(target?.milestones.map((milestone) => milestone.year) ?? [])
    );
    const annual = baseYear === '' ? '' : `${milestones[biggestMilestoneYear] ?? ''}/${baseYear}`;

    return {
      title: metric.title,
      baseYear,
      ratio,
      answer,
      ...milestones,
      annual,
    };
  });

  const tableContentRows = createRows(dataRows, milestoneYears);
  const headerRow = createHeaderRow(milestoneYears, columnsNb, year);

  return new Table({
    columnWidths: [2000, ...columnWidth],
    margins: { top: 60, bottom: 60, right: 60, left: 60 },
    width: { size: '100%', type: WidthType.PERCENTAGE },
    borders: tableBorders,
    rows: [headerRow, ...tableContentRows],
  });
};
