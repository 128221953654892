import { DataCollectionLevel } from 'containers/Esrs/pieces/DataCollection';
import {
  MappedDisclosureRequirements,
  Materiality,
  MaterialityState,
  MetricMaterialityState,
  ParentMaterialMetrics,
} from '../DoubleMaterialityAssessment.d';
import {
  Esrs_MaterialMetricTag_Constraint_,
  Esrs_MaterialMetricTag_Update_Column_,
  GetDrMaterialMetricsDocument_,
  GetEsrsStandardDocument_,
  GetMaterialStandardDocument_,
  GetRequiredTagsMaterialMetricsDocument_,
  GetSubsidiaryRequiredTagsMaterialMetricsDocument_,
  useUpdateSubsidiariesMaterialityMutation,
  useUpsertDrMaterialMetricsMutation,
} from 'models';
import { getMetricRefNumber } from 'containers/Esrs/pieces/DisclosureRequirements/Metrics/Metrics.hooks';
import { useCallback } from 'react';
import { MaterialMetric, Metric } from '../../MaterialityAssessment.d';
import { mapMaterialTags } from '../DoubleMaterialityAssessment.hooks';

export const isParentMaterial = (
  ref: string,
  parentMetrics: ParentMaterialMetrics,
  parentMateriality?: MaterialityState | Materiality
) => {
  if (parentMateriality === MaterialityState.notMaterial) return false;
  return parentMetrics?.some((metric) => {
    return metric.metricRef === ref && metric.isMaterial === true;
  });
};

type PartialMetricsData = Omit<Metric, 'childrenMetrics'>;
interface MaybeHasChild extends PartialMetricsData {
  childrenMetrics?: { childMetric?: MaybeHasChild | null }[];
}

export const isParentMaterialForSub = (
  ref: string,
  parentMetrics: ParentMaterialMetrics,
  parentMateriality?: MaterialityState | Materiality
) => {
  if (parentMateriality === MaterialityState.notMaterial) return false;
  return parentMetrics
    ?.filter((m) => m.metric.childrenMetrics_aggregate.aggregate?.count === 0)
    .some((metric) => {
      return (
        metric.metricRef === ref &&
        metric.isMaterial === true &&
        metric.dataCollection === DataCollectionLevel.subsidiaries
      );
    });
};

const hasChildOnSubLevel = (
  metric: MaybeHasChild,
  parentMetrics?: ParentMaterialMetrics
): boolean => {
  const groupMetric = parentMetrics?.find((m) => m.metricRef === metric.reference);
  if (!!metric?.childrenMetrics?.length) {
    return metric.childrenMetrics?.some(
      (child) => child?.childMetric && hasChildOnSubLevel(child?.childMetric, parentMetrics)
    );
  }
  return groupMetric?.dataCollection === DataCollectionLevel.subsidiaries;
};

export const handleMetric = (
  metric: Metric,
  materialMetrics: MaterialMetric[],
  isDRAssessed: boolean,
  isCollectOnly: boolean,
  parentMetrics?: ParentMaterialMetrics
) => {
  const additionalTypes =
    metric.additionalTypes.map((addType) => {
      const type = addType.additionalType;
      return {
        reference: type.reference,
        title: type.title ?? '',
        helpText: type.description ?? '',
      };
    }) ?? [];
  const voluntaryTagIndex = additionalTypes.findIndex((e) => e.title === 'Voluntary');
  if (voluntaryTagIndex !== -1) {
    additionalTypes.unshift(additionalTypes.splice(voluntaryTagIndex, 1)[0]);
  }
  let materiality = MetricMaterialityState.toAssess;
  const materialMetric = materialMetrics?.find((m) => m.metricRef === metric.reference);
  const groupMetric = parentMetrics?.find((m) => m.metricRef === metric.reference);

  if (materialMetric && isDRAssessed) {
    if (isCollectOnly) {
      if (metric.childrenMetrics.length) {
        if (hasChildOnSubLevel(metric as MaybeHasChild, parentMetrics) && groupMetric?.isMaterial) {
          materiality = MetricMaterialityState.collectDataMandatory;
        } else {
          materiality = materialMetric.isMaterial
            ? MetricMaterialityState.collectData
            : MetricMaterialityState.doNotCollect;
        }
      } else if (
        groupMetric?.isMaterial &&
        groupMetric.dataCollection === DataCollectionLevel.subsidiaries
      ) {
        materiality = MetricMaterialityState.collectDataMandatory;
      } else {
        materiality = materialMetric.isMaterial
          ? MetricMaterialityState.collectData
          : MetricMaterialityState.doNotCollect;
      }
    } else if (materialMetric.isDataGatheringOnly) {
      materiality = MetricMaterialityState.gatherData;
    } else if (materialMetric.isMaterial === true) {
      materiality = MetricMaterialityState.material;
    } else if (materialMetric.isMaterial === false) {
      materiality = MetricMaterialityState.notMaterial;
    }
  }
  const metricRefNumber = getMetricRefNumber(metric);
  return {
    materiality,
    additionalTypes,
    metricRefNumber,
  };
};

export const updateAllMateriality = (
  metrics: MappedDisclosureRequirements[number]['metrics'],
  materiality: MaterialityState | null,
  drMaterialMetrics: MaterialMetric[]
) => {
  const isMaterial =
    materiality === MaterialityState.material ||
    materiality === MaterialityState.gatherData ||
    materiality === MaterialityState.collectData
      ? true
      : materiality === MaterialityState.notMaterial ||
          materiality === MaterialityState.doNotCollect
        ? false
        : null;
  const isEntitySpecific = (m: MappedDisclosureRequirements[number]['metrics'][number]) => {
    return m.additionalTypes.some((type) => type.additionalType.reference === 'Entity-specific');
  };
  return metrics.map((m) => ({
    metricRef: m.reference,
    isMaterial: isEntitySpecific(m) ? false : isMaterial,
    isDataGatheringOnly: isEntitySpecific(m) ? false : materiality === MaterialityState.gatherData,
    metric: m,
    dataCollection: drMaterialMetrics.find((mm) => mm.metricRef === m.reference)?.dataCollection,
  }));
};

export const checkMateriality = (value: MetricMaterialityState, isCollectOnly: boolean) => {
  if (isCollectOnly) {
    if (value === MetricMaterialityState.collectData) {
      return true;
    } else if (value === MetricMaterialityState.doNotCollect) {
      return false;
    }
  } else {
    if (value === MetricMaterialityState.material || value === MetricMaterialityState.gatherData) {
      return true;
    } else if (value === MetricMaterialityState.notMaterial) {
      return false;
    } else {
      return null;
    }
  }
};
const shouldDataCollectionLevelBeGroup = (drRef?: string) => {
const relevantDrs = ['E1-8', 'E1-9', 'S1-16']
  if (relevantDrs.includes(drRef)) return true;
  return false;
};

export const useSaveDrMetrics = () => {
  const [upsertMaterialMetrics] = useUpsertDrMaterialMetricsMutation();
  const [updateSubsidiariesMateriality] = useUpdateSubsidiariesMaterialityMutation();

  return useCallback(
    (metrics: MaterialMetric[], isGroup: boolean, materialStandardId: string, drRef?: string) => {
      const defaultDataCollection = isGroup
        ? DataCollectionLevel.subsidiaries
        : DataCollectionLevel.company;
      return upsertMaterialMetrics({
        variables: {
          objects: metrics.map((m) => ({
            metricRef: m.metricRef,
            isMaterial: m.isMaterial,
            isDataGatheringOnly: m.isDataGatheringOnly,
            materialStandardId,
            materialMetricTags: {
              data: mapMaterialTags(m.metric),
              on_conflict: {
                constraint:
                  Esrs_MaterialMetricTag_Constraint_.MaterialMetricTagMaterialMetricIdTagTypeKey_,
                update_columns: [Esrs_MaterialMetricTag_Update_Column_.TagType_],
              },
            },
            dataCollection: isGroup
              ? shouldDataCollectionLevelBeGroup(drRef)
                ? DataCollectionLevel.group
                : DataCollectionLevel.subsidiaries
              : DataCollectionLevel.company,
          })),
        },
        refetchQueries: [
          GetDrMaterialMetricsDocument_,
          GetEsrsStandardDocument_,
          GetMaterialStandardDocument_,
          GetRequiredTagsMaterialMetricsDocument_,
          GetSubsidiaryRequiredTagsMaterialMetricsDocument_,
        ],
      }).then(() => {
        const hasMaterialMetrics = metrics.some((mm) => mm.isMaterial !== false);
        const hasSubsidiaryLevel = metrics.some(
          (mm) => (mm.dataCollection ?? defaultDataCollection) === DataCollectionLevel.subsidiaries
        );
        if (isGroup && hasMaterialMetrics && hasSubsidiaryLevel) {
          updateSubsidiariesMateriality({
            variables: { id: materialStandardId },
          });
        }
      });
    },
    [upsertMaterialMetrics]
  );
};
