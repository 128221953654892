import { Box, HStack, ListItem, OrderedList, useDisclosure, VStack } from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { AutoResizeTextarea } from 'Atoms';
import {
  DatapointFieldsFragment_,
  ShortUser,
  useUpdateDatapointMdrmDisclosureMutation,
} from 'models';
import { Modal, RadioCardGroup } from 'Molecules';
import { UserAvatar } from 'Organisms';
import { useEffect, useRef, useState } from 'react';
import { Typography } from 'Tokens';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { AddIcon } from 'Tokens/Icons/Function';

const listItemStyle = {
  fontSize: 'md',
  fontWeight: 'normal',
  fontFamily: 'celsiaFont',
  color: 'text.default',
  lineHeight: 'sm',
};

const MDRMLearnMore = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  return (
    <Modal
      size="sm"
      title="What are MDR-M?"
      isOpen={isOpen}
      onClose={onClose}
      hasFooter={false}
      isCentered
    >
      <VStack w="100%" spacing="8px">
        <Typography variant="body">
          Minimum disclosure requirement – Metrics MDR-M – Metrics in relation to material
          sustainability matters
        </Typography>
        <OrderedList>
          <ListItem {...listItemStyle}>
            <Typography variant="body">
              The undertaking shall apply the requirements for the content of disclosures in this
              provision when it discloses on the metrics it has in place with regard to each
              material sustainability matter.
            </Typography>
          </ListItem>
          <ListItem {...listItemStyle}>
            <Typography variant="body">
              The objective of this Minimum Disclosure Requirement is to provide an understanding of
              the metrics the undertaking uses to track the effectiveness of its actions to manage
              material sustainability matters.
            </Typography>
          </ListItem>
          <ListItem {...listItemStyle}>
            <Typography variant="body">
              The undertaking shall disclose any metrics that it uses to evaluate performance and
              effectiveness, in relation to a material impact, risk or opportunity
            </Typography>
          </ListItem>
          <ListItem {...listItemStyle}>
            <Typography variant="body">
              Metrics shall include those defined in ESRS, as well as metrics identified on an
              entity-specific basis, whether taken from other sources or developed by the
              undertaking itself.
            </Typography>
          </ListItem>
          <ListItem {...listItemStyle}>
            <Typography variant="body">For each metric, the undertaking shall:</Typography>
          </ListItem>
        </OrderedList>

        <Typography variant="body">
          (a) disclose the methodologies and significant assumptions behind the metric, including
          the limitations of the methodologies used;
        </Typography>
        <Typography variant="body">
          (b) disclose whether the measurement of the metric is validated by an external body other
          than the assurance provider and, if so, which body;
        </Typography>
        <Typography variant="body">
          (c) label and define the metric using meaningful, clear and precise names and
          descriptions;
        </Typography>
        <Typography variant="body">
          (d) when currency is specified as the unit of measure, use the presentation currency of
          its financial statements.
        </Typography>
      </VStack>
    </Modal>
  );
};

const DisclosureInput = ({
  disclosureDetails,
  setDisclosureDetails,
}: {
  disclosureDetails: string;
  setDisclosureDetails: (value: string) => void;
}) => {
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const user: ShortUser | null = useUserData();

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setIsTyping(true);
    setDisclosureDetails(newValue);

    // Change 'typing' state after 1 second of inactivity
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setIsTyping(false);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, []);

  return (
    <Box w="100%" mt="-6px">
      <AutoResizeTextarea
        placeholder="Document where MDR-M is disclosed (optional)"
        value={disclosureDetails}
        onChange={handleInputChange}
        variant="ghost"
        minHeight="68px"
        px="16px"
        py="12px"
      />
      {disclosureDetails && (
        <HStack paddingBottom="12px" paddingTop="8px" justifyContent="start">
          <UserAvatar user={user as ShortUser} size="xs" />
          <Typography variant="detail" color="text.muted">
            {isTyping ? 'Typing...' : 'All changes saved'}
          </Typography>
        </HStack>
      )}
    </Box>
  );
};

export const MDRMModal = ({
  isOpen,
  onClose,
  dataPoint,
}: {
  isOpen: boolean;
  onClose: () => void;
  dataPoint: DatapointFieldsFragment_ | undefined;
}) => {
  const [hasMDRM, setHasMDRM] = useState<boolean | null | undefined>(dataPoint?.isMdrmDisclosed);
  const [disclosureDetails, setDisclosureDetails] = useState<string>(
    dataPoint?.mdrmElsewhereDisclosure ?? ''
  );
  const [updateDatapointMdrmDisclosure] = useUpdateDatapointMdrmDisclosureMutation();
  const {
    isOpen: isLearnMoreOpen,
    onOpen: onLearnMoreOpen,
    onClose: onLearnMoreClose,
  } = useDisclosure();

  const handleChange = (isMdrmDisclosed: boolean, mdrmElsewhereDisclosure: string) => {
    updateDatapointMdrmDisclosure({
      variables: {
        datapointId: dataPoint?.id,
        isMdrmDisclosed,
        mdrmElsewhereDisclosure,
      },
    });
    onClose();
  };

  useEffect(() => {
    setHasMDRM(dataPoint?.isMdrmDisclosed);
  }, [dataPoint]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="MDR-M options"
      helpIcon
      onHelpClick={onLearnMoreOpen}
      size="sm"
      hasFooter={!hasMDRM}
      onConfirm={() => handleChange(false, disclosureDetails)}
      isCentered
    >
      <VStack w="100%" alignItems="stretch">
        <RadioCardGroup
          value={
            hasMDRM === undefined || hasMDRM === null ? '' : hasMDRM ? 'disclose' : 'elsewhere'
          }
          onChange={(v) => setHasMDRM(v === 'disclose')}
          cardWidth="456px"
          customBorderWidth="2px"
          customClick={(option) => {
            if (option.value === 'disclose') {
              handleChange(true, disclosureDetails);
            }
          }}
          options={[
            {
              title: 'Disclose MDR-M',
              subtitle: 'Disclose MDR-M for this data point',
              value: 'disclose',
              titleLeftIcon: AddIcon,
            },
            {
              title: 'MDR-M disclosed elsewhere',
              subtitle:
                'Choose this if you have disclosed the information for this MDR-M elsewhere in your report. Please add a note to describe where the information is disclosed.',
              value: 'elsewhere',
              titleLeftIcon: ArrowUpRightIcon,
              content: (
                <DisclosureInput
                  disclosureDetails={disclosureDetails}
                  setDisclosureDetails={setDisclosureDetails}
                />
              ),
            },
          ]}
        />
      </VStack>
      <MDRMLearnMore isOpen={isLearnMoreOpen} onClose={onLearnMoreClose} />
    </Modal>
  );
};
