import React from 'react';
import { useCookieConsent, CookieConsent, DEFAULT_COOKIE_CONSENT } from '.';
import { HStack, VStack } from '@chakra-ui/react';
import { useTranslation } from 'utils/translation';
import { Button, Checkbox } from 'Atoms';
import './CookieConsent.css';
import { Typography } from 'Tokens';

const PopupWrapper = ({ children }: { children: React.ReactNode }) => (
  <VStack
    animation="slide 2s forwards"
    width="428px"
    position="absolute"
    bottom="16px"
    right="0"
    marginRight="64px"
    alignItems="flex-start"
    background="bg.elevated.overlay"
    shadow="interactive.accent"
    color="text.default"
    borderRadius="10px"
    padding="20px"
    spacing="20px"
  >
    {children}
  </VStack>
);

const OPTIONS: { [key: string]: { disabled: boolean } } = {
  essential: { disabled: true },
  analytics: { disabled: false },
  marketing: { disabled: false },
  functional: { disabled: false },
};

const WHITELISTED_URLS = ['novata.com', 'stratsys.com', 'localhost'];
const ALLOWED_EMBED_URLS = ['novata.com', 'stratsys.com', 'stratsys.se'];

export const CookieConsentModal = () => {
  const { t } = useTranslation('common');
  const { consent, onUpdate } = useCookieConsent();
  const [customize, setCustomize] = React.useState(false);
  const [selections, setSelections] = React.useState<CookieConsent>(DEFAULT_COOKIE_CONSENT);

  const onAcceptSelections = () => {
    onUpdate({ ...selections, isSetup: true });
  };

  if (
    consent.isSetup ||
    WHITELISTED_URLS.some((link) => window.location.origin.includes(link)) ||
    ALLOWED_EMBED_URLS.some(
      (link) => (document.location.ancestorOrigins?.[0] || document.referrer).includes(link) // ancestorOrigins is not supported in firefox, document.referrer is unreliable
    )
  )
    return null;

  if (customize) {
    return (
      <PopupWrapper>
        <VStack spacing="4px" alignItems="flex-start">
          <Typography variant="h3" color="inherit">
            {t('common:cookies.title')}
          </Typography>
          <Typography variant="body" color="inherit">
            {t('common:cookies.description')}
          </Typography>
        </VStack>
        <VStack spacing="16px" alignItems="flex-start" color="inherit">
          {Object.keys(OPTIONS).map((key) => (
            <HStack key={key}>
              <Checkbox
                alignSelf="baseline"
                key={key}
                isChecked={selections[key as keyof CookieConsent] ?? false}
                isDisabled={OPTIONS[key].disabled}
                onChange={(e) =>
                  setSelections((currentSelection) => ({
                    ...currentSelection,
                    [key]: e.target.checked,
                  }))
                }
              />
              <Typography variant="body">{t(`common:cookies.${key}`)}</Typography>
            </HStack>
          ))}
        </VStack>

        <HStack>
          <Button onClick={() => onAcceptSelections()}>{t('common:cookies.accept')}</Button>
          <Button
            variant="ghost"
            onClick={() =>
              onUpdate({
                isSetup: true,
                essential: true,
                analytics: false,
                marketing: false,
                functional: false,
              })
            }
          >
            {t('common:cookies.decline')}
          </Button>
        </HStack>
      </PopupWrapper>
    );
  }

  return (
    <PopupWrapper>
      <VStack spacing="4px" alignItems="flex-start">
        <Typography variant="h3" color="inherit">
          {t('common:cookies.title')}
        </Typography>
        <Typography variant="body" color="inherit">
          {t('common:cookies.description')}
        </Typography>
      </VStack>
      <HStack textTransform="uppercase">
        <Button
          variant="primary"
          onClick={() =>
            onUpdate({
              isSetup: true,
              analytics: true,
              marketing: true,
              essential: true,
              functional: true,
            })
          }
        >
          {t('common:cookies.accept')}
        </Button>
        <Button
          onClick={() => {
            setCustomize(true);
            setSelections(consent);
          }}
        >
          {t('common:cookies.customize')}
        </Button>
        <Button
          variant="ghost"
          onClick={() =>
            onUpdate({
              isSetup: true,
              essential: true,
              analytics: false,
              marketing: false,
              functional: false,
            })
          }
        >
          {t('common:cookies.decline')}
        </Button>
      </HStack>
    </PopupWrapper>
  );
};
